// Normalize styles

// border-box by default
*, *:before, *:after {
  box-sizing: border-box;
}

// HTML5 block elements
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
  @include reset;
}

template {
  display: none;
}

// textarea scrollbars
textarea {
  overflow: auto;
}
