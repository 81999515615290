// user page
#userstatus {

	@extend %top-bar;

	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	div {
		margin: 0 0.25em;

		&.name {
			flex: 10 1 auto;
		}

		&.active {
			flex: 0 0 9em;
			margin-right: -1.5em;

			input {
				margin-left: 0;
			}

			label {
				width: auto;
			}
		}

		&.expire {

			input {
				width: 10.5em;
			}

			label {
				width: 3em;
			}

		}

	}

}

#name {
	width: 100%;
	font-size: 1.25em;
	font-weight: 600;
}

#token {
	max-width: 8em;
	overflow: hidden;
	text-overflow: ellipsis;
}
