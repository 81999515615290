// modal dialog
#modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	z-index: 9999;
	overflow: hidden;
	transition: height 0s linear 300ms;

	div {
		position: absolute;
		display: block;
		width: 25em;
		height: 18em;
		max-width: 90%;
		max-height: 90%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 3px 3px 3em 3px;
		margin: auto;
		border-radius: 3px;
		transform: translateY(-100vh);
		transition: transform 300ms cubic-bezier(0.7, -0.4, 1, 0);
	}

	&.progress div {
		padding-bottom: 3px;
		height: 4.5em;
	}

	header {
		position: static;
		height: auto;
		font-weight: 600;
		padding: 3px 0.5em 5px 0.5em;
		border-radius: 3px;
	}

	p {
		margin: 0.5em;
	}

	progress {
		display: block;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 100%;
		height: 2em;
		margin: 5px 0;
		padding: 0;
		border-radius: 3px;
	}

	#nav {
		position: absolute;
		bottom: 0.5em;
		right: 0.5em;
		@include reset-list;

		li {
			display: inline-block;
			margin: 0 0 0 4px;
		}
	}

	.button {
		width: 5em;
		height: 2em;
		line-height: 2;
	}

	&.active {

		height: 100%;
		transition-delay: 0s;

		div {
			transform: translateY(0vh);
			transition: transform 300ms cubic-bezier(0.6, 1.6, 0.8, 0.8);
		}

	}

}
