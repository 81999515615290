// Generic Sass mixins

// reset padding and margin
@mixin reset {
  padding: 0;
  margin: 0;
}

// reset a list
@mixin reset-list {
  list-style-type: none;
  @include reset;
}

// responsive breakpoints
@mixin respond($bp) {
  @media (min-width: map-get($breakpoint, $bp)) {
    @content;
  }
}

// top bar (extend)
%top-bar {
  position: fixed;
  top: 0;
  left: map-get($menu-width, 'small');
  right: 0;
  height: $top-bar-height;
  padding: 0.25em 2em;
  margin: 0;
  z-index: 100;

	// first responsive layout reference
	// define all in sequence to ensure MQ packer works
	@include respond('small') {
    left: map-get($menu-width, 'medium');
  }

  @include respond('medium') {
    left: map-get($menu-width, 'small');
  }

  @include respond('large') {
    left: map-get($menu-width, 'large');
  }
}

// button bar (extend)
%button-bar {
  position: fixed;
  bottom: 0;
  left: map-get($menu-width, 'small');
  right: 0;
  padding: 8px 2em 0 2em;
  margin: 0;
  z-index: 100;

  @include respond('medium') {
    left: map-get($menu-width, 'medium');
  }

  @include respond('large') {
    left: map-get($menu-width, 'large');
  }

  button, .button {
    margin: 0 0 4px 4px;
  }
}
