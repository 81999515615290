// Base styles
html, body {
  width: 100%;
  height: 100%;
  font-family: $font-family;
  font-weight: 300;
  font-size: ($font-size-small / $font-base-size) * 100%;
  @include reset;

  @include respond('medium') {
    font-size: ($font-size-medium / $font-base-size) * 100%;
  }

  @include respond('large') {
    font-size: ($font-size-large / $font-base-size) * 100%;
  }
}

html {
	touch-action: manipulation;
}

body {
  line-height: 1.3;
  padding: 5em 0;
	overflow-y: scroll;
}

table, input, output, textarea, select, datalist, option, pre, button {
  font-family: $font-family;
  font-size: 1em;
  font-weight: 300;
}

pre {
	font-family: $font-mono;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 600;
  @include reset;
}

h1 {
	width: 100%;
  font-size: 1.75em;
  font-weight: 300;
  margin-bottom: 0.2em;

  span {
    display: block;
    font-size: 0.75em;
  }

  header & {
    line-height: 1.75;
    margin-left: 2rem;
  }
}

h2 {
  font-size: 1.25em;

  span {
    display: block;
  }
}

h3 {
	font-size: 1em;
}

p {
  margin: 0 0 1em 0;
}

ul {
  list-style-type: disc;

  ul {
    list-style-type: square;
  }
}

em {
  font-style: normal;
}

hr {
	height: 1px;
	border: 0 none;
	margin: 8px 0;
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  @include reset;
  user-select: none;
}

#report form > h2 {
  margin-top: 3em;
  margin-bottom: 0.2em;
}

article {

  h2 {
    margin-top: 3em;
  }

  li {
    margin-bottom: 0.5em;
  }

  img {
    margin: 1em 0;
  }

}
