// dashboard styles
body#dashboard {

	padding: 1em;

	header, main {
		position: static;
		display: block;
		width: auto;
		min-width: 18.5em;
		height: auto;
		padding: 0;
		margin: 0 auto;
	}

	main {
		padding-bottom: 1em
	}

	$header-height: 3.5em;

	header {

		div {
			margin: 5px;
			overflow: hidden;
		}

		h1 {
			float: left;
			font-size: 1em;
			width: 12em;
			height: $header-height;
			padding: 0.7em 2em 0 1em;
			margin: 0;
			background-color: #ee7207;
			border-radius: 0 0 3em 0;

			path {
				fill: #000;
				stroke-width: 0;

				&.em, &.dot {
					fill: #fff;
				}
			}
		}

		.logobig {
			float: right;
			width: 9em;
			max-height: $header-height;
			margin: 0 1em;
		}

		p {
			font-size: 1.25em;
			font-weight: 600;
			text-align: center;
			padding: 0 140px;
			margin: 0.7em 0 0 0;
		}

	}

	p.system {
		font-size: 1.5em;
		text-align: center;
		margin: 1em auto;

		&::before {
			animation: spin 5s linear infinite;
		}
	}

}

body#dashboard, body#report {

	main {

		&, div.sectiongroup {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}

		&.sectiongroup {
			display: block;
		}

		&.restricted {
			max-width: 1200px;
		}

		&.notablereport {
			flex-direction: column;
		}

		section, article {
			flex: 1 1 17em;
			padding: 0 0 5px 0;
			margin: 5px;

			div {
				flex-wrap: wrap;
				margin: 5px;
			}

			button {
				order: 8;
				width: auto;
				padding-left: 0.5em;
				padding-right: 0.5em;
			}

			p.message {
				order: 9;
				width: 100%;
				text-align: center;
				padding: 0;
				margin: 0.5em;

				a {
					color: #ee7207 !important;
				}
			}

			p#themetoggler, p#themetoggler_demo {
				cursor: pointer;
			}

		}

		aside.note {
			width: 75%;
			text-align: left;
			margin: 1.5em auto;
			padding: 0.5em 2em;

			p {
				margin-bottom: 0;		
			}
		}

		div#files, div#reports, div#flows {
			// text-align: center;

			h2 {
				margin-top: 3em;
				margin-bottom: 1em;
			}

			ul {
				list-style-type: none;
				padding: 0 0 1em 0;
				max-width: 300px;
				margin-top: 2em;

				&.files {
					max-width: 400px;
				}
				
				li {
					margin-bottom: 0.5em;

					button {
						display: inline-block;
						width: 100%;
						white-space: nowrap;
    				overflow: hidden;
    				text-overflow: ellipsis;

						span {
							padding-left: 1em;
						}
						
						span:after {
							content: '\e837';
							visibility: hidden;
							display: inline-block;
							font-family: icons;
							font-weight: 400;
							padding-left: 0.5em;
						}
					}

					button:hover {
						
						span:after {
							visibility: visible;
						}
					}

				}

			}
		}

		div#contact {
			// text-align: center;

			// h2 {
			// 	margin-top: 3em;
			// }

			// img {
			// 	margin: 0 auto;
			// }

			a:after {
				content: '\e820';
				display: inline-block;
				font-family: icons;
				font-weight: 400;
				padding-left: 0.5em;
			}

		}
	}

}

// report sections
body#report main section {
	flex-basis: 80%;

	&.wide {
		flex-basis: 100%;
	}

	@include respond('small') {
		flex-basis: 40%;
	}

	@include respond('medium') {
		flex-basis: 21%;
	}
}
