// card grid layout
$cardWidth:   20em;
$cardMargin:  1.5vw;
$imgMax:      40vh;

.linked {
  text-align: center;
  padding: 0.5em;
  margin: 0 0 1em 0;

  img, video {
    max-width: $imgMax;
    max-height: $imgMax;
    margin: 0.5em auto 0;
  }
}

.cards {

  @include reset-list;

  & > li {
    display: inline-block;
    width: $cardWidth;
    position: relative;
    vertical-align: top;
    padding: 0 0 3.5em 0;
    margin: 0 $cardMargin $cardMargin 0;

    &.reveal {
      animation: cardreveal 1.2s ease;
    }

  }

  figure {
    max-height: $imgMax;
    overflow-x: hidden;
    overflow-y: auto;
  }

  img {
    margin: 0 auto;
    cursor: pointer;
  }

  .merchant {
    display: block;
    font-weight: 600;
  }

  .new {
    margin: 0;
  }

  .highlight {
    font-weight: 600;
    text-align: center;
    padding: 0.25em 0.5em;
  }

  .tests, ul {
    padding: 0.25em 0.5em;
    margin: 0;
  }

  time {
    float: right;
  }

  ul.none {
    display: none;
  }

  ul li {
    display: inline-block;
    padding: 0.1em 1em 0.1em 0;
  }

  ul.link {
    text-align: center;
    
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0;

    li {
      padding: 1em;
      margin: 0;
      font-weight: 600;
    }

    // li:last-child {
      // float: right;
    // }

  }

}

@supports (display: grid) {

  .cards {
    margin-top: 1em;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax($cardWidth, 1fr));
    grid-auto-rows: minmax($cardWidth, auto);
    grid-gap: $cardMargin;

    & > li {
      width: auto;
      margin: 0;
    }

    @include respond('medium') {

      .col2 {
        grid-column-end: span 2;
      }

      .row2 {
        grid-row-end: span 2;

        figure {
          max-height: $imgMax * 1.6;
        }
      }

    }

  }

}


// reveal card
@keyframes cardreveal {
	0% { transform: translateY(4em); opacity: 0; }
	100% { transform: translateY(0); opacity: 1; }
}
