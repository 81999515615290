// Global Sass variables

// fonts
$font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font-mono: Consolas, Menlo, "DejaVu Mono", monospace;
$font-base-size: 16px;
$font-size-large: 16px;
$font-size-medium: 15px;
$font-size-small: 14px;

// responsive breakpoints
$breakpoint: (
	'small':	30em,
	'medium': 56em,
	'large':	70em
);

// menu
$menu-width: (
	'small': 	3em,
	'medium': 3em,
	'large': 	9em
);

// layouts
$top-bar-height: 3em;

// colours
$color-status: (
	'0':    inherit,
	'500':  #88f,
	'1000': #8c6,
	'1500': #bbc,
	'2000': #fa6,
	'3000': #d55,
	'4000': #f99
);

// colours
$color-status-hover: (
	'500':  darken(#88f, 10%),
	'1000': darken(#8c6, 10%),
	'1500': darken(#bbc, 10%),
	'2000': darken(#fa6, 10%),
	'3000': darken(#d55, 10%),
	'4000': darken(#f99, 10%)
);

// colours
$color-istate: (
	'1': #bbc,
	'01': #8c6,
	'00': #d55
);

// colours
$color-istate-hover: (
	'1': darken(#8c6, 10%),
	'01': darken(#bbc, 10%),
	'00': darken(#d55, 10%)
);
