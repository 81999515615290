// media image preview
#preview {

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	overflow: hidden;
	outline: 0 none;

	&.active {
		height: 100%;
	}

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 3.6em;
		right: 3.6em;
		overflow: hidden;
	}

	img {
		position: absolute;
		max-width: 100%;
		max-height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		cursor: zoom-in;

		&.zoom {
			max-width: none;
			max-height: none;
		}
	}

	article {
		max-width: 30em;
		max-height: 90%;
		padding: 0.8em;
	}

	p {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		padding: 0.8em 0.5em;
		margin: 0;
		transform: translateY(0);
		transition: transform 0.3s ease 0.6s;
	}

	div:hover p {
		transform: translateY(100%);
	}

	a {
		position: absolute;
		font-size: 3em;
		width: 1.2em;
		height: 100%;
		left: 0;
		top: 0;

		&:after {
			position: absolute;
			font-family: "icons";
			content: '\e805';
			width: 100%;
			height: 1em;
			line-height: 1;
			top: 0;
			bottom: 0;
			text-align: center;
			margin: auto;
		}

	}

	a.next {
		left: auto;
		right: 0;

		&:after {
			content: '\e806';
		}
	}

	a.close {
		height: 1.25em;
		left: auto;
		right: 0;

		&:after {
			content: '\e81d';
		}
	}

}
