// theming

// status drop-down mixin
@mixin status-select($name) {
	option[value="#{$name}"] {
		background-color: #{map-get($color-status, $name)};
		border-top-color: #{map-get($color-status, $name)};
	}
}

// status SVG mixin
@mixin status-svg($name) {
	svg .status#{$name}, svg g .status#{$name} {
		fill: #{map-get($color-status, $name)} !important;

		&:hover {
			fill: #{map-get($color-status-hover, $name)} !important;
		}
	}

	svg g:hover .status#{$name} {
		fill: #{map-get($color-status-hover, $name)} !important;
	}
}

// theme mixin
@mixin theme (
	$name,
	$back1, $back2, $back3, $back4,
	$fore1, $fore2, $fore3,
	$hint1, $hint2, $hint3,
	$on, $ontext,
	$off, $offtext,
	$avg, $avgtext) {

	$body: 'body';
	$dash: 'body#dashboard';

	@if ($name != '') {
		$body: $body + '.' + $name;
		$dash: $dash + '.' + $name;
	}

	#{$body} {

		// placeholders
		%top-bar {
			background-color: $back4;
		}

		%button-bar {
			background-color: rgba($back2, 0.8);
		}

		// base
		color: $fore1;
	  background-color: $back2;

		hr {
			background-color: $back2;
		}

		// file drop
		#filehover {
			color: $hint2;
			background-color: rgba($hint1, 0.25);
		}

		a:link, a:visited {
		  color: $hint1;
		}

		a:hover, a:focus, a:active {
		  color: $hint3;
		}

		aside.note {
			border: 1px solid $hint2;

			p, h3 {
				color: $hint2;
			}
		}

		// layout
		footer {
		  color: $fore2;
		}

		// navigation menu
		nav.menu {
			background-color: $back1;

			a {
		    &:link, &:visited {
		      color: $fore2;
		    }

		    &:hover, &:focus, &:active {
		      color: $fore1;
		    }

		    &.active {
		      background-color: $back2;
		    }
			}

			.home a {
		    background-color: $back4;
		  }

		  .sep {
		    border-bottom: 3px double $back2;
		  }

		}

		// forms
		fieldset {
			background-color: $back1;

			&.flows {
				background-color: $back4;
			}
		}

		// fieldset tints
		#testmessages, #testmain, #testissues, #testmedia, #testextra, .separator {
			border-top: 6px solid $hint1;
		}

		#dupissuemessage div {
			color: #fff;
			background-color: rgba($offtext, 0.7);
		}

		legend {
			background-color: $hint1;
		}

		label {
			color: $fore2;
		}

		input, output, textarea, select {
			color: $fore1;
			background-color: $back2;

			&.autofill, &.autofill ~ label, &.invalid ~ label {
				color: $offtext;
			}

			&.invalid {
				border: 1px solid $offtext;
			}

			&:focus ~ label {
				font-weight: 600;
				color: $fore1;
			}

			&[disabled], &[readonly] {
				background-color: transparent;
			}
		}

		div.invalid {
			border: 1px solid $offtext;
		}

		select {
			background: $back2 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10"><path d="M2,4h2L3,6Lz" stroke-width="0" fill="rgba(128,128,128,.2)"/></svg>') no-repeat 100% 50%;
		}

		optgroup, optgroup option {
			background-color: $back4;
		}

		output {
			background-color: transparent;
		}

		input[type="checkbox"], input[type="radio"] {
			& ~ label {
				&::before, &::after {
					color: $offtext;
					background-color: $off;
				}
				&::after {
					background-color: rgba(#fff, 0.5);
				}
			}

			&:checked ~ label {
				color: $fore1;

				&::before {
					color: $ontext;
					background-color: $on;
				}
			}

		}

		pre span.domain {
			color: $offtext;
		}

		pre span.query {
			color: $fore2;
		}

		form div p {
			color: $fore2;
		}

		form nav.login {
			padding: 0 2em;
			background-color: transparent;
			border-top: 3px solid rgba($back2, 0.8);

			a {
				color: $back2;
				background-color: rgba($hint2, 0.8);

				&:hover, &:focus {
					background-color: $hint2;
				}
			}
		}

		form .comment p {
			color: $fore1;
		}

		button, .button {
			color: $fore2;
			background-color: $back3;

			&:link, &:visited {
				color: $fore2;
			}

			&:hover, &:focus, &:active {
				color: $fore1;
				background-color: $hint1;
			}

			&[disabled] {
				color: $back1;

				&:hover, &:focus, &:active {
					background-color: $back3;
				}

			}
		}

		#readonly .comment p {
			color: $fore1;
		}

		// tables
		form.active table {

			th {
				&:hover, &:focus {
					color: $fore3;
				}
			}

		}

		thead {
			background-color: $hint1;
		}

		th {
			&.sort-1 {
				background-color: $hint3;
			}

			&.sort1 {
				background-color: $hint2;
			}
		}

		tbody tr:nth-child(2n) {
			background-color: rgba(0,0,0,0.1);
		}

		tbody tr.sep td[rowspan] {
			background-color: $back2;
		}

		tbody tr:hover td {
			background-color: rgba($hint1, 0.2);
		}

		tbody tr.selected {
			background-color: rgba($hint2, 0.3);
		}

		tbody tr.selected:nth-child(2n) {
			background-color: rgba($hint2, 0.4);
		}

		col.country {
			border-right: 1px solid $hint2;
		}

		table.report {

			border: 1px solid $hint2;

			tr.sep {
				border-top: 1px solid $hint2;
			}

			tr.firstrec {
				border-top: 5px solid $hint2;
			}

			tr.subtotal td {
				border-top: 3px double $hint2;
			}

			th.title, td.title {
				border-right: 1px solid $hint2;
			}

			.sum {
				border-left: 1px solid $hint2;
			}

			.z {
				color: rgba($fore1, 0.25);
			}

			.green {
				background-color: rgba($ontext, 0.35);
			}

			.red {
				background-color: rgba($offtext, 0.45);
			}

			.yellow {
				background-color: rgba($avgtext, 0.35);
			}

			.pink {
				background-color: rgba($offtext, 0.15);
			}

		}

		table.ops {

			th, td {
				border-right: 1px solid $hint2;
			}

			td.u {
				color: $off;
			}

			td.o {
				color: $on;
			}

			tr.sepm {
				border-top: 1px dotted $back4;
			}

			tbody tr, tbody tr:hover td {
				background-color: transparent;
			}

		}

		table.issue {

			tbody tr, tbody tr:hover td {
				background-color: transparent;
			}

		}

		table.extended {

			tbody tr {
				background-color: transparent;
			}

			td.sep {
				border-top: 1px solid $hint2;
			}
			td.sepm {
				border-top: 1px dotted $back3;
			}

			td.agg {
				background-color: rgba($fore2, 0.3) !important;
			}

			td.alltotal {
				background-color: rgba($fore2, 0.3) !important;
				border-top: 1px solid $hint2;
				border-bottom: 1px solid $hint2;
			}

			td.subtotal {
				background-color: rgba($hint3, 0.2) !important;
				border-top: 1px solid $hint2;
				// border-bottom: 1px solid $hint2;
			}

			td.total {
				background-color: rgba($hint2, 0.3);
				border-top: 1px solid $hint2;
			}

		}

		.showhide {
			color: $fore2;
		}

		// card grid
		.linked {
			border: 3px solid $back1;
		}

		.cards {

			& > li {
				background-color: $back4;
			}

			figure {
				background-color: $back1;
				border-top: 4px solid $back4;
			}

			ul {
				border-top: 1px solid $back1;
			}

			.merchant {
				color: $fore3;
			}

			.highlight {
				color: $hint1;
			}

		}

		// widgets
		section {

			background-color: $back4;

			h2 {
				color: $fore2;
				background-color: $back1;

				em {
					color: $hint2;
				}

				em.alt {
					text-transform: uppercase;
					color: $fore1;
				}

				span:hover, span:focus {
					color: $fore1;
				}
			}

			h3 {
				color: $hint1;
			}

			a:hover h3, a:focus h3 {
				color: $hint3;
			}

			.main {
				color: $fore3;
			}

			.active {
				color: $on;
			}

			.inactive {
				color: $off;
			}

			.secondary {
				color: $fore2;
			}

			progress {
				color: $on;
				background: $off;
			}

			progress::-webkit-progress-bar {
				background: $off;
			}

			progress::-moz-progress-bar {
				background: $on;
			}

			p.help {

				a::before {
					color: $fore2;
					background-color: $back3;
				}

				a:hover::before, a:focus::before {
					color: $fore1;
					background-color: $hint2;
				}

			}

		}

		fieldset section {
			background-color: transparent;
		}

		// test popup
		div.testcard {
			background-color: $back4;
			border: 1px solid $hint1;
		}

		// modal alert
		#modal {
			background-color: rgba($back2, 0.75);

			div {
				color: $fore1;
				background-color: $back4;
			}

			header {
				color: $fore1;
				background-color: $hint2;
			}

			progress {
				color: $on;
				background: $fore2;
				border: 1px solid $fore2;
			}

			progress::-webkit-progress-bar {
				background: $fore2;
			}

			progress::-moz-progress-bar {
				background: $on;
			}

		}

		// media control
		ol#media {

			li {
				border: 1px solid $hint2;

				&.dragging {
					background-color: $back1;
					border: 3px solid $hint2;
				}

				&.dragovertop {
					border-left: 4px dotted $fore1;
				}

				&.dragoverend {
					border-right: 4px dotted $fore1;
				}
			}

			h3 {
				background-color: $hint2;
			}

			figure, p.alert {
				background-color: $back2;
			}

			figure {
				border-bottom: 1px dotted $hint2;
			}

			span {
				color: $fore1;
			}

		}

		// media preview
		#preview {
			background-color: rgba($back2, 0.9);

			article {
				background-color: $fore1;
				color: $back1;
				border: 2px solid $hint1;
				border-top-width: 15px;
				border-radius: 2px;
			}

			p {
				color: $fore1;
				background-color: rgba($back2, 0.8);
			}

			a {
				color: $fore2;

				&:hover, &:focus {
					color: $fore3;
				}
			}

		}

		// messages
		#testissues section p::before {
			color: $off;
		}

		#testmessages {

			p.company span {
				color: $ontext;
			}

			a.unread p.company span {
				color: $offtext;
			}

		}

		div.messageoverlay {
			background-color: rgba($back2, 0);
		}

		#messagepanel {
			background-color: $back3;

			h2 {
				color: $fore1;
				background-color: transparent;
			}

			nav {
				background-color: $back3;
			}

			button {
				background-color: $back1;

				&:hover, &:focus, &:active {
					background-color: $hint1;
				}
			}

		}

		#comments {
			background-color: $back4;
			border: 5px solid $back4;
		}

		blockquote.speech {

			p {
				color: $fore1;
				background-color: $hint2;
			}

			p:after {
				border-color: $hint2 $hint2 transparent transparent;
			}

			cite {
				color: $fore2;
			}

			a {
				color: $fore1;
				text-decoration: underline;
			}

			q {
				font-style: italic;
			}

			&.client {

				p {
					background-color: $back3;
				}

				p:after {
					border-color: $back3 transparent transparent $back3;
				}

			}

		}

		&.showmessagepanel div.messageoverlay {
			background-color: rgba($back2, 0.5);
		}

		// list filters
		#filter {

			div.filters {
				background-color: inherit;
			}

		}

		div.filters {
			background-color: $back1;
		}

		div.advanced {
			background-color: $back4;

			fieldset {
				background-color: inherit;
			}
		}

		td.m3g {
			color: $fore2;
		}

		td.overdue {
			color: $off;
		}

		td.gmomit {
			color: $off;
		}

		td.using {
			color: $on;
		}

		// update list
		.updated {

			p {
				color: $fore1;
				background-color: $back2;
			}

			ol {
				background-color: $back4;

				&:before {
					border-bottom-color: $back4;
				}
			}

			li.sep {
				border-top: 1px dotted $fore2;
			}

			&:hover p {
				background-color: $hint1;
			}

		}

		// test page
		li.new {

			&:before { color: $offtext; }

		}

		li.autofill input {
			color: $offtext;
		}

		#ref {
			background-color: transparent;
		}

		input#ref {
			&:hover, &:focus {
				background-color: $back2;
			}
		}

		#status {

			option {
				background-color: $back2;
				border-top-color: $back2;
			}

			@include status-select("0");
			@include status-select("500");
			@include status-select("1000");
			@include status-select("1500");
			@include status-select("2000");
			@include status-select("3000");
			@include status-select("4000");

		}

		// HTML charts
		ul.barchart {
			background-color: $back1;
		}

		// SVG charts
		svg:not([aria-label="A chart."]) {

			circle, ellipse, path {
				stroke: $back4;
				fill: $hint1;

				&#ma, &#eh {
					stroke: none;
				}

			}

			path, rect {
				fill: $hint1;

				&.alt {
					fill: $hint3;
				}

				&:hover {
					fill: $hint2;
				}
			}

			g {
				rect {
					fill: $hint1;
				}

				&:hover rect {
					fill: $hint2;
				}
			}

			path.low, rect.low {
				fill: $on;

				&:hover {
					fill: $ontext;
				}
			}

			g:hover rect.low { fill: $ontext; }

			path.high, rect.high {
				fill: $off;

				&:hover {
					fill: $offtext;
				}
			}

			g:hover rect.high { fill: $offtext; }

			path.i1, rect.i1 {
				fill: $back3;

				&:hover {
					fill: $fore2;
				}
			}

			g:hover rect.i1 { fill: $fore2; }

			path.i01, rect.i01 {
				fill: $ontext;

				&:hover {
					fill: $on;
				}
			}

			g:hover rect.i01 { fill: $on; }

			path.i00, rect.i00 {
				fill: $offtext;

				&:hover {
					fill: $off;
				}
			}

			g:hover rect.i00 { fill: $off; }

			text {
				fill: $fore1;
			}
	
			text.total {
				fill: $hint1;
			}
	
			text.axism {
				fill: $fore2;
			}
	
			line {
				stroke: $fore2;
			}
		
		}

		.inner {
			fill: $back2 !important;
		}

		section .inner {
			fill: $back4 !important;
		}

		@include status-svg("500");
		@include status-svg("1000");
		@include status-svg("1500");
		@include status-svg("2000");
		@include status-svg("3000");
		@include status-svg("4000");

		svg [class^='classification'] {
			fill: $hint1;

			&:hover {
				fill: $hint2;
			}
		}

		path.classificationnone {
			fill: $hint3;
		}

		// map
		svg.map {

			border-top-color: $on;

			path {
				fill: $back3;
			}

			path:hover {
				fill: $hint1 !important;
			}

		}

		// map pin
		ul.pin {
			color: $fore1;
			background-color: $back2;
			border: 1px solid $back2;

			&::after {
				border-top-color: $back2;
			}

			li.title, li.subtitle {
				background-color: $back4;
			}

			a:hover, a:focus, a:active {
				background-color: $hint2;
			}
		}

		.subfields {
			background-color: $back1;
		}

		// user page
		#name {
			background-color: transparent;
		}

	}

	// dashboard page
	#{$dash} {

		header, main {
			background-color: transparent;
		}

		header {

			div {
				background-color: $back4;
				border: 5px solid $back1;
			}

			h1 {
				color: $fore2;
			}

			sup {
				color: $fore1;
			}

			.maintenance {
				text-transform: uppercase;
				color: $fore3;
				animation: textfade 0.3s linear 1.5s alternate 9;
			}

		}

		p.success {
			color: $on;
		}

	}

}

// default theme
@include theme(
	'',
	#ddd, #fff, #ccc, #eee,
	#333, #777, #555,
	lighten(#ee7207, 15%), lighten(#ee7207, 5%), lighten(#ee7207, 25%),
	#090, lighten(#090, 10%),
	#c00, lighten(#c00, 10%),
	#f1c232, lighten(#f1c232, 10%),
);

// dark theme
@include theme(
	'dark',
	#2a2a2a, #1d1d1d, #313131, #363636,
	#e0e0e0, #929292, #fff,
	#ee7207, darken(#ee7207, 15%), lighten(#ee7207, 15%),
	#8c6, darken(#8c6, 15%),
	#d55, darken(#d55, 15%),
	#f1c232, darken(#f1c232, 15%)
);
