@charset "UTF-8";
header, #filter, #teststatus, #userstatus {
  position: fixed;
  top: 0;
  left: 3em;
  right: 0;
  height: 3em;
  padding: 0.25em 2em;
  margin: 0;
  z-index: 100; }
  @media (min-width: 30em) {
    header, #filter, #teststatus, #userstatus {
      left: 3em; } }
  @media (min-width: 56em) {
    header, #filter, #teststatus, #userstatus {
      left: 3em; } }
  @media (min-width: 70em) {
    header, #filter, #teststatus, #userstatus {
      left: 9em; } }

form nav {
  position: fixed;
  bottom: 0;
  left: 3em;
  right: 0;
  padding: 8px 2em 0 2em;
  margin: 0;
  z-index: 100; }
  @media (min-width: 56em) {
    form nav {
      left: 3em; } }
  @media (min-width: 70em) {
    form nav {
      left: 9em; } }
  form nav button, form nav .button {
    margin: 0 0 4px 4px; }

body {
  color: #333;
  background-color: #fff; }
  body header, body #filter, body #teststatus, body #userstatus {
    background-color: #eee; }
  body form nav, form body nav {
    background-color: rgba(255, 255, 255, 0.8); }
  body hr {
    background-color: #fff; }
  body #filehover {
    color: #f87f16;
    background-color: rgba(250, 154, 72, 0.25); }
  body a:link, body a:visited {
    color: #fa9a48; }
  body a:hover, body a:focus, body a:active {
    color: #fbb579; }
  body aside.note {
    border: 1px solid #f87f16; }
    body aside.note p, body aside.note h3 {
      color: #f87f16; }
  body footer {
    color: #777; }
  body nav.menu {
    background-color: #ddd; }
    body nav.menu a:link, body nav.menu a:visited {
      color: #777; }
    body nav.menu a:hover, body nav.menu a:focus, body nav.menu a:active {
      color: #333; }
    body nav.menu a.active {
      background-color: #fff; }
    body nav.menu .home a {
      background-color: #eee; }
    body nav.menu .sep {
      border-bottom: 3px double #fff; }
  body fieldset {
    background-color: #ddd; }
    body fieldset.flows {
      background-color: #eee; }
  body #testmessages, body #testmain, body #testissues, body #testmedia, body #testextra, body .separator {
    border-top: 6px solid #fa9a48; }
  body #dupissuemessage div {
    color: #fff;
    background-color: rgba(255, 0, 0, 0.7); }
  body legend {
    background-color: #fa9a48; }
  body label {
    color: #777; }
  body input, body output, body textarea, body select {
    color: #333;
    background-color: #fff; }
    body input.autofill, body input.autofill ~ label, body input.invalid ~ label, body output.autofill, body output.autofill ~ label, body output.invalid ~ label, body textarea.autofill, body textarea.autofill ~ label, body textarea.invalid ~ label, body select.autofill, body select.autofill ~ label, body select.invalid ~ label {
      color: red; }
    body input.invalid, body output.invalid, body textarea.invalid, body select.invalid {
      border: 1px solid red; }
    body input:focus ~ label, body output:focus ~ label, body textarea:focus ~ label, body select:focus ~ label {
      font-weight: 600;
      color: #333; }
    body input[disabled], body input[readonly], body output[disabled], body output[readonly], body textarea[disabled], body textarea[readonly], body select[disabled], body select[readonly] {
      background-color: transparent; }
  body div.invalid {
    border: 1px solid red; }
  body select {
    background: #fff url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10"><path d="M2,4h2L3,6Lz" stroke-width="0" fill="rgba(128,128,128,.2)"/></svg>') no-repeat 100% 50%; }
  body optgroup, body optgroup option {
    background-color: #eee; }
  body output {
    background-color: transparent; }
  body input[type="checkbox"] ~ label::before, body input[type="checkbox"] ~ label::after, body input[type="radio"] ~ label::before, body input[type="radio"] ~ label::after {
    color: red;
    background-color: #c00; }
  body input[type="checkbox"] ~ label::after, body input[type="radio"] ~ label::after {
    background-color: rgba(255, 255, 255, 0.5); }
  body input[type="checkbox"]:checked ~ label, body input[type="radio"]:checked ~ label {
    color: #333; }
    body input[type="checkbox"]:checked ~ label::before, body input[type="radio"]:checked ~ label::before {
      color: #00cc00;
      background-color: #090; }
  body pre span.domain {
    color: red; }
  body pre span.query {
    color: #777; }
  body form div p {
    color: #777; }
  body form nav.login {
    padding: 0 2em;
    background-color: transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8); }
    body form nav.login a {
      color: #fff;
      background-color: rgba(248, 127, 22, 0.8); }
      body form nav.login a:hover, body form nav.login a:focus {
        background-color: #f87f16; }
  body form .comment p {
    color: #333; }
  body button, body .button {
    color: #777;
    background-color: #ccc; }
    body button:link, body button:visited, body .button:link, body .button:visited {
      color: #777; }
    body button:hover, body button:focus, body button:active, body .button:hover, body .button:focus, body .button:active {
      color: #333;
      background-color: #fa9a48; }
    body button[disabled], body .button[disabled] {
      color: #ddd; }
      body button[disabled]:hover, body button[disabled]:focus, body button[disabled]:active, body .button[disabled]:hover, body .button[disabled]:focus, body .button[disabled]:active {
        background-color: #ccc; }
  body #readonly .comment p {
    color: #333; }
  body form.active table th:hover, body form.active table th:focus {
    color: #555; }
  body thead {
    background-color: #fa9a48; }
  body th.sort-1 {
    background-color: #fbb579; }
  body th.sort1 {
    background-color: #f87f16; }
  body tbody tr:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.1); }
  body tbody tr.sep td[rowspan] {
    background-color: #fff; }
  body tbody tr:hover td {
    background-color: rgba(250, 154, 72, 0.2); }
  body tbody tr.selected {
    background-color: rgba(248, 127, 22, 0.3); }
  body tbody tr.selected:nth-child(2n) {
    background-color: rgba(248, 127, 22, 0.4); }
  body col.country {
    border-right: 1px solid #f87f16; }
  body table.report {
    border: 1px solid #f87f16; }
    body table.report tr.sep {
      border-top: 1px solid #f87f16; }
    body table.report tr.firstrec {
      border-top: 5px solid #f87f16; }
    body table.report tr.subtotal td {
      border-top: 3px double #f87f16; }
    body table.report th.title, body table.report td.title {
      border-right: 1px solid #f87f16; }
    body table.report .sum {
      border-left: 1px solid #f87f16; }
    body table.report .z {
      color: rgba(51, 51, 51, 0.25); }
    body table.report .green {
      background-color: rgba(0, 204, 0, 0.35); }
    body table.report .red {
      background-color: rgba(255, 0, 0, 0.45); }
    body table.report .yellow {
      background-color: rgba(244, 208, 98, 0.35); }
    body table.report .pink {
      background-color: rgba(255, 0, 0, 0.15); }
  body table.ops th, body table.ops td {
    border-right: 1px solid #f87f16; }
  body table.ops td.u {
    color: #c00; }
  body table.ops td.o {
    color: #090; }
  body table.ops tr.sepm {
    border-top: 1px dotted #eee; }
  body table.ops tbody tr, body table.ops tbody tr:hover td {
    background-color: transparent; }
  body table.issue tbody tr, body table.issue tbody tr:hover td {
    background-color: transparent; }
  body table.extended tbody tr {
    background-color: transparent; }
  body table.extended td.sep {
    border-top: 1px solid #f87f16; }
  body table.extended td.sepm {
    border-top: 1px dotted #ccc; }
  body table.extended td.agg {
    background-color: rgba(119, 119, 119, 0.3) !important; }
  body table.extended td.alltotal {
    background-color: rgba(119, 119, 119, 0.3) !important;
    border-top: 1px solid #f87f16;
    border-bottom: 1px solid #f87f16; }
  body table.extended td.subtotal {
    background-color: rgba(251, 181, 121, 0.2) !important;
    border-top: 1px solid #f87f16; }
  body table.extended td.total {
    background-color: rgba(248, 127, 22, 0.3);
    border-top: 1px solid #f87f16; }
  body .showhide {
    color: #777; }
  body .linked {
    border: 3px solid #ddd; }
  body .cards > li {
    background-color: #eee; }
  body .cards figure {
    background-color: #ddd;
    border-top: 4px solid #eee; }
  body .cards ul {
    border-top: 1px solid #ddd; }
  body .cards .merchant {
    color: #555; }
  body .cards .highlight {
    color: #fa9a48; }
  body section {
    background-color: #eee; }
    body section h2 {
      color: #777;
      background-color: #ddd; }
      body section h2 em {
        color: #f87f16; }
      body section h2 em.alt {
        text-transform: uppercase;
        color: #333; }
      body section h2 span:hover, body section h2 span:focus {
        color: #333; }
    body section h3 {
      color: #fa9a48; }
    body section a:hover h3, body section a:focus h3 {
      color: #fbb579; }
    body section .main {
      color: #555; }
    body section .active {
      color: #090; }
    body section .inactive {
      color: #c00; }
    body section .secondary {
      color: #777; }
    body section progress {
      color: #090;
      background: #c00; }
    body section progress::-webkit-progress-bar {
      background: #c00; }
    body section progress::-moz-progress-bar {
      background: #090; }
    body section p.help a::before {
      color: #777;
      background-color: #ccc; }
    body section p.help a:hover::before, body section p.help a:focus::before {
      color: #333;
      background-color: #f87f16; }
  body fieldset section {
    background-color: transparent; }
  body div.testcard {
    background-color: #eee;
    border: 1px solid #fa9a48; }
  body #modal {
    background-color: rgba(255, 255, 255, 0.75); }
    body #modal div {
      color: #333;
      background-color: #eee; }
    body #modal header {
      color: #333;
      background-color: #f87f16; }
    body #modal progress {
      color: #090;
      background: #777;
      border: 1px solid #777; }
    body #modal progress::-webkit-progress-bar {
      background: #777; }
    body #modal progress::-moz-progress-bar {
      background: #090; }
  body ol#media li {
    border: 1px solid #f87f16; }
    body ol#media li.dragging {
      background-color: #ddd;
      border: 3px solid #f87f16; }
    body ol#media li.dragovertop {
      border-left: 4px dotted #333; }
    body ol#media li.dragoverend {
      border-right: 4px dotted #333; }
  body ol#media h3 {
    background-color: #f87f16; }
  body ol#media figure, body ol#media p.alert {
    background-color: #fff; }
  body ol#media figure {
    border-bottom: 1px dotted #f87f16; }
  body ol#media span {
    color: #333; }
  body #preview {
    background-color: rgba(255, 255, 255, 0.9); }
    body #preview article {
      background-color: #333;
      color: #ddd;
      border: 2px solid #fa9a48;
      border-top-width: 15px;
      border-radius: 2px; }
    body #preview p {
      color: #333;
      background-color: rgba(255, 255, 255, 0.8); }
    body #preview a {
      color: #777; }
      body #preview a:hover, body #preview a:focus {
        color: #555; }
  body #testissues section p::before {
    color: #c00; }
  body #testmessages p.company span {
    color: #00cc00; }
  body #testmessages a.unread p.company span {
    color: red; }
  body div.messageoverlay {
    background-color: rgba(255, 255, 255, 0); }
  body #messagepanel {
    background-color: #ccc; }
    body #messagepanel h2 {
      color: #333;
      background-color: transparent; }
    body #messagepanel nav {
      background-color: #ccc; }
    body #messagepanel button {
      background-color: #ddd; }
      body #messagepanel button:hover, body #messagepanel button:focus, body #messagepanel button:active {
        background-color: #fa9a48; }
  body #comments {
    background-color: #eee;
    border: 5px solid #eee; }
  body blockquote.speech p {
    color: #333;
    background-color: #f87f16; }
  body blockquote.speech p:after {
    border-color: #f87f16 #f87f16 transparent transparent; }
  body blockquote.speech cite {
    color: #777; }
  body blockquote.speech a {
    color: #333;
    text-decoration: underline; }
  body blockquote.speech q {
    font-style: italic; }
  body blockquote.speech.client p {
    background-color: #ccc; }
  body blockquote.speech.client p:after {
    border-color: #ccc transparent transparent #ccc; }
  body.showmessagepanel div.messageoverlay {
    background-color: rgba(255, 255, 255, 0.5); }
  body #filter div.filters {
    background-color: inherit; }
  body div.filters {
    background-color: #ddd; }
  body div.advanced {
    background-color: #eee; }
    body div.advanced fieldset {
      background-color: inherit; }
  body td.m3g {
    color: #777; }
  body td.overdue {
    color: #c00; }
  body td.gmomit {
    color: #c00; }
  body td.using {
    color: #090; }
  body .updated p {
    color: #333;
    background-color: #fff; }
  body .updated ol {
    background-color: #eee; }
    body .updated ol:before {
      border-bottom-color: #eee; }
  body .updated li.sep {
    border-top: 1px dotted #777; }
  body .updated:hover p {
    background-color: #fa9a48; }
  body li.new:before {
    color: red; }
  body li.autofill input {
    color: red; }
  body #ref {
    background-color: transparent; }
  body input#ref:hover, body input#ref:focus {
    background-color: #fff; }
  body #status option {
    background-color: #fff;
    border-top-color: #fff; }
  body #status option[value="0"] {
    background-color: inherit;
    border-top-color: inherit; }
  body #status option[value="500"] {
    background-color: #88f;
    border-top-color: #88f; }
  body #status option[value="1000"] {
    background-color: #8c6;
    border-top-color: #8c6; }
  body #status option[value="1500"] {
    background-color: #bbc;
    border-top-color: #bbc; }
  body #status option[value="2000"] {
    background-color: #fa6;
    border-top-color: #fa6; }
  body #status option[value="3000"] {
    background-color: #d55;
    border-top-color: #d55; }
  body #status option[value="4000"] {
    background-color: #f99;
    border-top-color: #f99; }
  body ul.barchart {
    background-color: #ddd; }
  body svg:not([aria-label="A chart."]) circle, body svg:not([aria-label="A chart."]) ellipse, body svg:not([aria-label="A chart."]) path {
    stroke: #eee;
    fill: #fa9a48; }
    body svg:not([aria-label="A chart."]) circle#ma, body svg:not([aria-label="A chart."]) circle#eh, body svg:not([aria-label="A chart."]) ellipse#ma, body svg:not([aria-label="A chart."]) ellipse#eh, body svg:not([aria-label="A chart."]) path#ma, body svg:not([aria-label="A chart."]) path#eh {
      stroke: none; }
  body svg:not([aria-label="A chart."]) path, body svg:not([aria-label="A chart."]) rect {
    fill: #fa9a48; }
    body svg:not([aria-label="A chart."]) path.alt, body svg:not([aria-label="A chart."]) rect.alt {
      fill: #fbb579; }
    body svg:not([aria-label="A chart."]) path:hover, body svg:not([aria-label="A chart."]) rect:hover {
      fill: #f87f16; }
  body svg:not([aria-label="A chart."]) g rect {
    fill: #fa9a48; }
  body svg:not([aria-label="A chart."]) g:hover rect {
    fill: #f87f16; }
  body svg:not([aria-label="A chart."]) path.low, body svg:not([aria-label="A chart."]) rect.low {
    fill: #090; }
    body svg:not([aria-label="A chart."]) path.low:hover, body svg:not([aria-label="A chart."]) rect.low:hover {
      fill: #00cc00; }
  body svg:not([aria-label="A chart."]) g:hover rect.low {
    fill: #00cc00; }
  body svg:not([aria-label="A chart."]) path.high, body svg:not([aria-label="A chart."]) rect.high {
    fill: #c00; }
    body svg:not([aria-label="A chart."]) path.high:hover, body svg:not([aria-label="A chart."]) rect.high:hover {
      fill: red; }
  body svg:not([aria-label="A chart."]) g:hover rect.high {
    fill: red; }
  body svg:not([aria-label="A chart."]) path.i1, body svg:not([aria-label="A chart."]) rect.i1 {
    fill: #ccc; }
    body svg:not([aria-label="A chart."]) path.i1:hover, body svg:not([aria-label="A chart."]) rect.i1:hover {
      fill: #777; }
  body svg:not([aria-label="A chart."]) g:hover rect.i1 {
    fill: #777; }
  body svg:not([aria-label="A chart."]) path.i01, body svg:not([aria-label="A chart."]) rect.i01 {
    fill: #00cc00; }
    body svg:not([aria-label="A chart."]) path.i01:hover, body svg:not([aria-label="A chart."]) rect.i01:hover {
      fill: #090; }
  body svg:not([aria-label="A chart."]) g:hover rect.i01 {
    fill: #090; }
  body svg:not([aria-label="A chart."]) path.i00, body svg:not([aria-label="A chart."]) rect.i00 {
    fill: red; }
    body svg:not([aria-label="A chart."]) path.i00:hover, body svg:not([aria-label="A chart."]) rect.i00:hover {
      fill: #c00; }
  body svg:not([aria-label="A chart."]) g:hover rect.i00 {
    fill: #c00; }
  body svg:not([aria-label="A chart."]) text {
    fill: #333; }
  body svg:not([aria-label="A chart."]) text.total {
    fill: #fa9a48; }
  body svg:not([aria-label="A chart."]) text.axism {
    fill: #777; }
  body svg:not([aria-label="A chart."]) line {
    stroke: #777; }
  body .inner {
    fill: #fff !important; }
  body section .inner {
    fill: #eee !important; }
  body svg .status500, body svg g .status500 {
    fill: #88f !important; }
    body svg .status500:hover, body svg g .status500:hover {
      fill: #5555ff !important; }
  body svg g:hover .status500 {
    fill: #5555ff !important; }
  body svg .status1000, body svg g .status1000 {
    fill: #8c6 !important; }
    body svg .status1000:hover, body svg g .status1000:hover {
      fill: #6abf40 !important; }
  body svg g:hover .status1000 {
    fill: #6abf40 !important; }
  body svg .status1500, body svg g .status1500 {
    fill: #bbc !important; }
    body svg .status1500:hover, body svg g .status1500:hover {
      fill: #9e9eb6 !important; }
  body svg g:hover .status1500 {
    fill: #9e9eb6 !important; }
  body svg .status2000, body svg g .status2000 {
    fill: #fa6 !important; }
    body svg .status2000:hover, body svg g .status2000:hover {
      fill: #ff8e33 !important; }
  body svg g:hover .status2000 {
    fill: #ff8e33 !important; }
  body svg .status3000, body svg g .status3000 {
    fill: #d55 !important; }
    body svg .status3000:hover, body svg g .status3000:hover {
      fill: #d52b2b !important; }
  body svg g:hover .status3000 {
    fill: #d52b2b !important; }
  body svg .status4000, body svg g .status4000 {
    fill: #f99 !important; }
    body svg .status4000:hover, body svg g .status4000:hover {
      fill: #ff6666 !important; }
  body svg g:hover .status4000 {
    fill: #ff6666 !important; }
  body svg [class^='classification'] {
    fill: #fa9a48; }
    body svg [class^='classification']:hover {
      fill: #f87f16; }
  body path.classificationnone {
    fill: #fbb579; }
  body svg.map {
    border-top-color: #090; }
    body svg.map path {
      fill: #ccc; }
    body svg.map path:hover {
      fill: #fa9a48 !important; }
  body ul.pin {
    color: #333;
    background-color: #fff;
    border: 1px solid #fff; }
    body ul.pin::after {
      border-top-color: #fff; }
    body ul.pin li.title, body ul.pin li.subtitle {
      background-color: #eee; }
    body ul.pin a:hover, body ul.pin a:focus, body ul.pin a:active {
      background-color: #f87f16; }
  body .subfields {
    background-color: #ddd; }
  body #name {
    background-color: transparent; }

body#dashboard header, body#dashboard main {
  background-color: transparent; }

body#dashboard header div {
  background-color: #eee;
  border: 5px solid #ddd; }

body#dashboard header h1 {
  color: #777; }

body#dashboard header sup {
  color: #333; }

body#dashboard header .maintenance {
  text-transform: uppercase;
  color: #555;
  animation: textfade 0.3s linear 1.5s alternate 9; }

body#dashboard p.success {
  color: #090; }

body.dark {
  color: #e0e0e0;
  background-color: #1d1d1d; }
  body.dark header, body.dark #filter, body.dark #teststatus, body.dark #userstatus {
    background-color: #363636; }
  body.dark form nav, form body.dark nav {
    background-color: rgba(29, 29, 29, 0.8); }
  body.dark hr {
    background-color: #1d1d1d; }
  body.dark #filehover {
    color: #a44e05;
    background-color: rgba(238, 114, 7, 0.25); }
  body.dark a:link, body.dark a:visited {
    color: #ee7207; }
  body.dark a:hover, body.dark a:focus, body.dark a:active {
    color: #fa9a48; }
  body.dark aside.note {
    border: 1px solid #a44e05; }
    body.dark aside.note p, body.dark aside.note h3 {
      color: #a44e05; }
  body.dark footer {
    color: #929292; }
  body.dark nav.menu {
    background-color: #2a2a2a; }
    body.dark nav.menu a:link, body.dark nav.menu a:visited {
      color: #929292; }
    body.dark nav.menu a:hover, body.dark nav.menu a:focus, body.dark nav.menu a:active {
      color: #e0e0e0; }
    body.dark nav.menu a.active {
      background-color: #1d1d1d; }
    body.dark nav.menu .home a {
      background-color: #363636; }
    body.dark nav.menu .sep {
      border-bottom: 3px double #1d1d1d; }
  body.dark fieldset {
    background-color: #2a2a2a; }
    body.dark fieldset.flows {
      background-color: #363636; }
  body.dark #testmessages, body.dark #testmain, body.dark #testissues, body.dark #testmedia, body.dark #testextra, body.dark .separator {
    border-top: 6px solid #ee7207; }
  body.dark #dupissuemessage div {
    color: #fff;
    background-color: rgba(191, 38, 38, 0.7); }
  body.dark legend {
    background-color: #ee7207; }
  body.dark label {
    color: #929292; }
  body.dark input, body.dark output, body.dark textarea, body.dark select {
    color: #e0e0e0;
    background-color: #1d1d1d; }
    body.dark input.autofill, body.dark input.autofill ~ label, body.dark input.invalid ~ label, body.dark output.autofill, body.dark output.autofill ~ label, body.dark output.invalid ~ label, body.dark textarea.autofill, body.dark textarea.autofill ~ label, body.dark textarea.invalid ~ label, body.dark select.autofill, body.dark select.autofill ~ label, body.dark select.invalid ~ label {
      color: #bf2626; }
    body.dark input.invalid, body.dark output.invalid, body.dark textarea.invalid, body.dark select.invalid {
      border: 1px solid #bf2626; }
    body.dark input:focus ~ label, body.dark output:focus ~ label, body.dark textarea:focus ~ label, body.dark select:focus ~ label {
      font-weight: 600;
      color: #e0e0e0; }
    body.dark input[disabled], body.dark input[readonly], body.dark output[disabled], body.dark output[readonly], body.dark textarea[disabled], body.dark textarea[readonly], body.dark select[disabled], body.dark select[readonly] {
      background-color: transparent; }
  body.dark div.invalid {
    border: 1px solid #bf2626; }
  body.dark select {
    background: #1d1d1d url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10"><path d="M2,4h2L3,6Lz" stroke-width="0" fill="rgba(128,128,128,.2)"/></svg>') no-repeat 100% 50%; }
  body.dark optgroup, body.dark optgroup option {
    background-color: #363636; }
  body.dark output {
    background-color: transparent; }
  body.dark input[type="checkbox"] ~ label::before, body.dark input[type="checkbox"] ~ label::after, body.dark input[type="radio"] ~ label::before, body.dark input[type="radio"] ~ label::after {
    color: #bf2626;
    background-color: #d55; }
  body.dark input[type="checkbox"] ~ label::after, body.dark input[type="radio"] ~ label::after {
    background-color: rgba(255, 255, 255, 0.5); }
  body.dark input[type="checkbox"]:checked ~ label, body.dark input[type="radio"]:checked ~ label {
    color: #e0e0e0; }
    body.dark input[type="checkbox"]:checked ~ label::before, body.dark input[type="radio"]:checked ~ label::before {
      color: #60ac39;
      background-color: #8c6; }
  body.dark pre span.domain {
    color: #bf2626; }
  body.dark pre span.query {
    color: #929292; }
  body.dark form div p {
    color: #929292; }
  body.dark form nav.login {
    padding: 0 2em;
    background-color: transparent;
    border-top: 3px solid rgba(29, 29, 29, 0.8); }
    body.dark form nav.login a {
      color: #1d1d1d;
      background-color: rgba(164, 78, 5, 0.8); }
      body.dark form nav.login a:hover, body.dark form nav.login a:focus {
        background-color: #a44e05; }
  body.dark form .comment p {
    color: #e0e0e0; }
  body.dark button, body.dark .button {
    color: #929292;
    background-color: #313131; }
    body.dark button:link, body.dark button:visited, body.dark .button:link, body.dark .button:visited {
      color: #929292; }
    body.dark button:hover, body.dark button:focus, body.dark button:active, body.dark .button:hover, body.dark .button:focus, body.dark .button:active {
      color: #e0e0e0;
      background-color: #ee7207; }
    body.dark button[disabled], body.dark .button[disabled] {
      color: #2a2a2a; }
      body.dark button[disabled]:hover, body.dark button[disabled]:focus, body.dark button[disabled]:active, body.dark .button[disabled]:hover, body.dark .button[disabled]:focus, body.dark .button[disabled]:active {
        background-color: #313131; }
  body.dark #readonly .comment p {
    color: #e0e0e0; }
  body.dark form.active table th:hover, body.dark form.active table th:focus {
    color: #fff; }
  body.dark thead {
    background-color: #ee7207; }
  body.dark th.sort-1 {
    background-color: #fa9a48; }
  body.dark th.sort1 {
    background-color: #a44e05; }
  body.dark tbody tr:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.1); }
  body.dark tbody tr.sep td[rowspan] {
    background-color: #1d1d1d; }
  body.dark tbody tr:hover td {
    background-color: rgba(238, 114, 7, 0.2); }
  body.dark tbody tr.selected {
    background-color: rgba(164, 78, 5, 0.3); }
  body.dark tbody tr.selected:nth-child(2n) {
    background-color: rgba(164, 78, 5, 0.4); }
  body.dark col.country {
    border-right: 1px solid #a44e05; }
  body.dark table.report {
    border: 1px solid #a44e05; }
    body.dark table.report tr.sep {
      border-top: 1px solid #a44e05; }
    body.dark table.report tr.firstrec {
      border-top: 5px solid #a44e05; }
    body.dark table.report tr.subtotal td {
      border-top: 3px double #a44e05; }
    body.dark table.report th.title, body.dark table.report td.title {
      border-right: 1px solid #a44e05; }
    body.dark table.report .sum {
      border-left: 1px solid #a44e05; }
    body.dark table.report .z {
      color: rgba(224, 224, 224, 0.25); }
    body.dark table.report .green {
      background-color: rgba(96, 172, 57, 0.35); }
    body.dark table.report .red {
      background-color: rgba(191, 38, 38, 0.45); }
    body.dark table.report .yellow {
      background-color: rgba(201, 155, 14, 0.35); }
    body.dark table.report .pink {
      background-color: rgba(191, 38, 38, 0.15); }
  body.dark table.ops th, body.dark table.ops td {
    border-right: 1px solid #a44e05; }
  body.dark table.ops td.u {
    color: #d55; }
  body.dark table.ops td.o {
    color: #8c6; }
  body.dark table.ops tr.sepm {
    border-top: 1px dotted #363636; }
  body.dark table.ops tbody tr, body.dark table.ops tbody tr:hover td {
    background-color: transparent; }
  body.dark table.issue tbody tr, body.dark table.issue tbody tr:hover td {
    background-color: transparent; }
  body.dark table.extended tbody tr {
    background-color: transparent; }
  body.dark table.extended td.sep {
    border-top: 1px solid #a44e05; }
  body.dark table.extended td.sepm {
    border-top: 1px dotted #313131; }
  body.dark table.extended td.agg {
    background-color: rgba(146, 146, 146, 0.3) !important; }
  body.dark table.extended td.alltotal {
    background-color: rgba(146, 146, 146, 0.3) !important;
    border-top: 1px solid #a44e05;
    border-bottom: 1px solid #a44e05; }
  body.dark table.extended td.subtotal {
    background-color: rgba(250, 154, 72, 0.2) !important;
    border-top: 1px solid #a44e05; }
  body.dark table.extended td.total {
    background-color: rgba(164, 78, 5, 0.3);
    border-top: 1px solid #a44e05; }
  body.dark .showhide {
    color: #929292; }
  body.dark .linked {
    border: 3px solid #2a2a2a; }
  body.dark .cards > li {
    background-color: #363636; }
  body.dark .cards figure {
    background-color: #2a2a2a;
    border-top: 4px solid #363636; }
  body.dark .cards ul {
    border-top: 1px solid #2a2a2a; }
  body.dark .cards .merchant {
    color: #fff; }
  body.dark .cards .highlight {
    color: #ee7207; }
  body.dark section {
    background-color: #363636; }
    body.dark section h2 {
      color: #929292;
      background-color: #2a2a2a; }
      body.dark section h2 em {
        color: #a44e05; }
      body.dark section h2 em.alt {
        text-transform: uppercase;
        color: #e0e0e0; }
      body.dark section h2 span:hover, body.dark section h2 span:focus {
        color: #e0e0e0; }
    body.dark section h3 {
      color: #ee7207; }
    body.dark section a:hover h3, body.dark section a:focus h3 {
      color: #fa9a48; }
    body.dark section .main {
      color: #fff; }
    body.dark section .active {
      color: #8c6; }
    body.dark section .inactive {
      color: #d55; }
    body.dark section .secondary {
      color: #929292; }
    body.dark section progress {
      color: #8c6;
      background: #d55; }
    body.dark section progress::-webkit-progress-bar {
      background: #d55; }
    body.dark section progress::-moz-progress-bar {
      background: #8c6; }
    body.dark section p.help a::before {
      color: #929292;
      background-color: #313131; }
    body.dark section p.help a:hover::before, body.dark section p.help a:focus::before {
      color: #e0e0e0;
      background-color: #a44e05; }
  body.dark fieldset section {
    background-color: transparent; }
  body.dark div.testcard {
    background-color: #363636;
    border: 1px solid #ee7207; }
  body.dark #modal {
    background-color: rgba(29, 29, 29, 0.75); }
    body.dark #modal div {
      color: #e0e0e0;
      background-color: #363636; }
    body.dark #modal header {
      color: #e0e0e0;
      background-color: #a44e05; }
    body.dark #modal progress {
      color: #8c6;
      background: #929292;
      border: 1px solid #929292; }
    body.dark #modal progress::-webkit-progress-bar {
      background: #929292; }
    body.dark #modal progress::-moz-progress-bar {
      background: #8c6; }
  body.dark ol#media li {
    border: 1px solid #a44e05; }
    body.dark ol#media li.dragging {
      background-color: #2a2a2a;
      border: 3px solid #a44e05; }
    body.dark ol#media li.dragovertop {
      border-left: 4px dotted #e0e0e0; }
    body.dark ol#media li.dragoverend {
      border-right: 4px dotted #e0e0e0; }
  body.dark ol#media h3 {
    background-color: #a44e05; }
  body.dark ol#media figure, body.dark ol#media p.alert {
    background-color: #1d1d1d; }
  body.dark ol#media figure {
    border-bottom: 1px dotted #a44e05; }
  body.dark ol#media span {
    color: #e0e0e0; }
  body.dark #preview {
    background-color: rgba(29, 29, 29, 0.9); }
    body.dark #preview article {
      background-color: #e0e0e0;
      color: #2a2a2a;
      border: 2px solid #ee7207;
      border-top-width: 15px;
      border-radius: 2px; }
    body.dark #preview p {
      color: #e0e0e0;
      background-color: rgba(29, 29, 29, 0.8); }
    body.dark #preview a {
      color: #929292; }
      body.dark #preview a:hover, body.dark #preview a:focus {
        color: #fff; }
  body.dark #testissues section p::before {
    color: #d55; }
  body.dark #testmessages p.company span {
    color: #60ac39; }
  body.dark #testmessages a.unread p.company span {
    color: #bf2626; }
  body.dark div.messageoverlay {
    background-color: rgba(29, 29, 29, 0); }
  body.dark #messagepanel {
    background-color: #313131; }
    body.dark #messagepanel h2 {
      color: #e0e0e0;
      background-color: transparent; }
    body.dark #messagepanel nav {
      background-color: #313131; }
    body.dark #messagepanel button {
      background-color: #2a2a2a; }
      body.dark #messagepanel button:hover, body.dark #messagepanel button:focus, body.dark #messagepanel button:active {
        background-color: #ee7207; }
  body.dark #comments {
    background-color: #363636;
    border: 5px solid #363636; }
  body.dark blockquote.speech p {
    color: #e0e0e0;
    background-color: #a44e05; }
  body.dark blockquote.speech p:after {
    border-color: #a44e05 #a44e05 transparent transparent; }
  body.dark blockquote.speech cite {
    color: #929292; }
  body.dark blockquote.speech a {
    color: #e0e0e0;
    text-decoration: underline; }
  body.dark blockquote.speech q {
    font-style: italic; }
  body.dark blockquote.speech.client p {
    background-color: #313131; }
  body.dark blockquote.speech.client p:after {
    border-color: #313131 transparent transparent #313131; }
  body.dark.showmessagepanel div.messageoverlay {
    background-color: rgba(29, 29, 29, 0.5); }
  body.dark #filter div.filters {
    background-color: inherit; }
  body.dark div.filters {
    background-color: #2a2a2a; }
  body.dark div.advanced {
    background-color: #363636; }
    body.dark div.advanced fieldset {
      background-color: inherit; }
  body.dark td.m3g {
    color: #929292; }
  body.dark td.overdue {
    color: #d55; }
  body.dark td.gmomit {
    color: #d55; }
  body.dark td.using {
    color: #8c6; }
  body.dark .updated p {
    color: #e0e0e0;
    background-color: #1d1d1d; }
  body.dark .updated ol {
    background-color: #363636; }
    body.dark .updated ol:before {
      border-bottom-color: #363636; }
  body.dark .updated li.sep {
    border-top: 1px dotted #929292; }
  body.dark .updated:hover p {
    background-color: #ee7207; }
  body.dark li.new:before {
    color: #bf2626; }
  body.dark li.autofill input {
    color: #bf2626; }
  body.dark #ref {
    background-color: transparent; }
  body.dark input#ref:hover, body.dark input#ref:focus {
    background-color: #1d1d1d; }
  body.dark #status option {
    background-color: #1d1d1d;
    border-top-color: #1d1d1d; }
  body.dark #status option[value="0"] {
    background-color: inherit;
    border-top-color: inherit; }
  body.dark #status option[value="500"] {
    background-color: #88f;
    border-top-color: #88f; }
  body.dark #status option[value="1000"] {
    background-color: #8c6;
    border-top-color: #8c6; }
  body.dark #status option[value="1500"] {
    background-color: #bbc;
    border-top-color: #bbc; }
  body.dark #status option[value="2000"] {
    background-color: #fa6;
    border-top-color: #fa6; }
  body.dark #status option[value="3000"] {
    background-color: #d55;
    border-top-color: #d55; }
  body.dark #status option[value="4000"] {
    background-color: #f99;
    border-top-color: #f99; }
  body.dark ul.barchart {
    background-color: #2a2a2a; }
  body.dark svg:not([aria-label="A chart."]) circle, body.dark svg:not([aria-label="A chart."]) ellipse, body.dark svg:not([aria-label="A chart."]) path {
    stroke: #363636;
    fill: #ee7207; }
    body.dark svg:not([aria-label="A chart."]) circle#ma, body.dark svg:not([aria-label="A chart."]) circle#eh, body.dark svg:not([aria-label="A chart."]) ellipse#ma, body.dark svg:not([aria-label="A chart."]) ellipse#eh, body.dark svg:not([aria-label="A chart."]) path#ma, body.dark svg:not([aria-label="A chart."]) path#eh {
      stroke: none; }
  body.dark svg:not([aria-label="A chart."]) path, body.dark svg:not([aria-label="A chart."]) rect {
    fill: #ee7207; }
    body.dark svg:not([aria-label="A chart."]) path.alt, body.dark svg:not([aria-label="A chart."]) rect.alt {
      fill: #fa9a48; }
    body.dark svg:not([aria-label="A chart."]) path:hover, body.dark svg:not([aria-label="A chart."]) rect:hover {
      fill: #a44e05; }
  body.dark svg:not([aria-label="A chart."]) g rect {
    fill: #ee7207; }
  body.dark svg:not([aria-label="A chart."]) g:hover rect {
    fill: #a44e05; }
  body.dark svg:not([aria-label="A chart."]) path.low, body.dark svg:not([aria-label="A chart."]) rect.low {
    fill: #8c6; }
    body.dark svg:not([aria-label="A chart."]) path.low:hover, body.dark svg:not([aria-label="A chart."]) rect.low:hover {
      fill: #60ac39; }
  body.dark svg:not([aria-label="A chart."]) g:hover rect.low {
    fill: #60ac39; }
  body.dark svg:not([aria-label="A chart."]) path.high, body.dark svg:not([aria-label="A chart."]) rect.high {
    fill: #d55; }
    body.dark svg:not([aria-label="A chart."]) path.high:hover, body.dark svg:not([aria-label="A chart."]) rect.high:hover {
      fill: #bf2626; }
  body.dark svg:not([aria-label="A chart."]) g:hover rect.high {
    fill: #bf2626; }
  body.dark svg:not([aria-label="A chart."]) path.i1, body.dark svg:not([aria-label="A chart."]) rect.i1 {
    fill: #313131; }
    body.dark svg:not([aria-label="A chart."]) path.i1:hover, body.dark svg:not([aria-label="A chart."]) rect.i1:hover {
      fill: #929292; }
  body.dark svg:not([aria-label="A chart."]) g:hover rect.i1 {
    fill: #929292; }
  body.dark svg:not([aria-label="A chart."]) path.i01, body.dark svg:not([aria-label="A chart."]) rect.i01 {
    fill: #60ac39; }
    body.dark svg:not([aria-label="A chart."]) path.i01:hover, body.dark svg:not([aria-label="A chart."]) rect.i01:hover {
      fill: #8c6; }
  body.dark svg:not([aria-label="A chart."]) g:hover rect.i01 {
    fill: #8c6; }
  body.dark svg:not([aria-label="A chart."]) path.i00, body.dark svg:not([aria-label="A chart."]) rect.i00 {
    fill: #bf2626; }
    body.dark svg:not([aria-label="A chart."]) path.i00:hover, body.dark svg:not([aria-label="A chart."]) rect.i00:hover {
      fill: #d55; }
  body.dark svg:not([aria-label="A chart."]) g:hover rect.i00 {
    fill: #d55; }
  body.dark svg:not([aria-label="A chart."]) text {
    fill: #e0e0e0; }
  body.dark svg:not([aria-label="A chart."]) text.total {
    fill: #ee7207; }
  body.dark svg:not([aria-label="A chart."]) text.axism {
    fill: #929292; }
  body.dark svg:not([aria-label="A chart."]) line {
    stroke: #929292; }
  body.dark .inner {
    fill: #1d1d1d !important; }
  body.dark section .inner {
    fill: #363636 !important; }
  body.dark svg .status500, body.dark svg g .status500 {
    fill: #88f !important; }
    body.dark svg .status500:hover, body.dark svg g .status500:hover {
      fill: #5555ff !important; }
  body.dark svg g:hover .status500 {
    fill: #5555ff !important; }
  body.dark svg .status1000, body.dark svg g .status1000 {
    fill: #8c6 !important; }
    body.dark svg .status1000:hover, body.dark svg g .status1000:hover {
      fill: #6abf40 !important; }
  body.dark svg g:hover .status1000 {
    fill: #6abf40 !important; }
  body.dark svg .status1500, body.dark svg g .status1500 {
    fill: #bbc !important; }
    body.dark svg .status1500:hover, body.dark svg g .status1500:hover {
      fill: #9e9eb6 !important; }
  body.dark svg g:hover .status1500 {
    fill: #9e9eb6 !important; }
  body.dark svg .status2000, body.dark svg g .status2000 {
    fill: #fa6 !important; }
    body.dark svg .status2000:hover, body.dark svg g .status2000:hover {
      fill: #ff8e33 !important; }
  body.dark svg g:hover .status2000 {
    fill: #ff8e33 !important; }
  body.dark svg .status3000, body.dark svg g .status3000 {
    fill: #d55 !important; }
    body.dark svg .status3000:hover, body.dark svg g .status3000:hover {
      fill: #d52b2b !important; }
  body.dark svg g:hover .status3000 {
    fill: #d52b2b !important; }
  body.dark svg .status4000, body.dark svg g .status4000 {
    fill: #f99 !important; }
    body.dark svg .status4000:hover, body.dark svg g .status4000:hover {
      fill: #ff6666 !important; }
  body.dark svg g:hover .status4000 {
    fill: #ff6666 !important; }
  body.dark svg [class^='classification'] {
    fill: #ee7207; }
    body.dark svg [class^='classification']:hover {
      fill: #a44e05; }
  body.dark path.classificationnone {
    fill: #fa9a48; }
  body.dark svg.map {
    border-top-color: #8c6; }
    body.dark svg.map path {
      fill: #313131; }
    body.dark svg.map path:hover {
      fill: #ee7207 !important; }
  body.dark ul.pin {
    color: #e0e0e0;
    background-color: #1d1d1d;
    border: 1px solid #1d1d1d; }
    body.dark ul.pin::after {
      border-top-color: #1d1d1d; }
    body.dark ul.pin li.title, body.dark ul.pin li.subtitle {
      background-color: #363636; }
    body.dark ul.pin a:hover, body.dark ul.pin a:focus, body.dark ul.pin a:active {
      background-color: #a44e05; }
  body.dark .subfields {
    background-color: #2a2a2a; }
  body.dark #name {
    background-color: transparent; }

body#dashboard.dark header, body#dashboard.dark main {
  background-color: transparent; }

body#dashboard.dark header div {
  background-color: #363636;
  border: 5px solid #2a2a2a; }

body#dashboard.dark header h1 {
  color: #929292; }

body#dashboard.dark header sup {
  color: #e0e0e0; }

body#dashboard.dark header .maintenance {
  text-transform: uppercase;
  color: #fff;
  animation: textfade 0.3s linear 1.5s alternate 9; }

body#dashboard.dark p.success {
  color: #8c6; }

svg.fraudscan {
  display: none; }

svg.fraudscan path.fstext, .logobig {
  fill: #222; }
  body.dark svg.fraudscan path.fstext, body.dark .logobig {
    fill: #ddd; }

.home a svg {
  display: none;
  max-width: 100%;
  max-height: 100%; }

.home a .logosml {
  display: block; }

@media (min-width: 70em) {
  .home a .logosml {
    display: none; }
  .home a .logobig {
    display: block; } }

*, *:before, *:after {
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
  padding: 0;
  margin: 0; }

template {
  display: none; }

textarea {
  overflow: auto; }

@font-face {
  font-family: 'icons';
  src: resolve("icons.eot");
  src: resolve("icons.eot#iefix") format("embedded-opentype"), resolve("icons.woff") format("woff"), resolve("icons.ttf") format("truetype"), resolve("icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before, [class*=" icon-"]:before, section .status500 a:before, section .status1000 a:before, section .status1500 a:before, section .status2000 a:before, section .status3000 a:before, section .status4000 a:before, body th.status0:before, body td.status0:before, body output.status0:before, body th.status500:before, body td.status500:before, body output.status500:before, body th.status1000:before, body td.status1000:before, body output.status1000:before, body th.status1500:before, body td.status1500:before, body output.status1500:before, body th.status2000:before, body td.status2000:before, body output.status2000:before, body th.status3000:before, body td.status3000:before, body output.status3000:before, body th.status4000:before, body td.status4000:before, body output.status4000:before, td.published:before, td.active:before, td.marketing:before, td.payment:before, td.careline:before, td.cancomment:before, td.canfix:before, td.canverify:before, td.canedit:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  user-select: none;
  speak: none; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  margin-left: 0.2em;
  margin-right: 0.2em; }

[class^="icon-file-"]:before, [class*=" icon-file-"]:before {
  width: 1.1em;
  padding: 0.1em 0; }

.icon-down-mini:before {
  content: '\e800'; }

.icon-left-mini:before {
  content: '\e801'; }

.icon-right-mini:before {
  content: '\e802'; }

.icon-up-mini:before {
  content: '\e803'; }

.icon-down-big:before {
  content: '\e804'; }

.icon-left-big:before {
  content: '\e805'; }

.icon-right-big:before {
  content: '\e806'; }

.icon-up-big:before {
  content: '\e807'; }

.icon-arrows-ccw:before {
  content: '\e808'; }

.icon-star:before {
  content: '\e809'; }

.icon-star-empty:before {
  content: '\e80a'; }

.icon-user:before {
  content: '\e80b'; }

.icon-users:before {
  content: '\e80c'; }

.icon-user-add:before {
  content: '\e80d'; }

.icon-clock:before {
  content: '\e80e'; }

.icon-cog:before {
  content: '\e80f'; }

.icon-search:before {
  content: '\e810'; }

.icon-attach:before {
  content: '\e811'; }

.icon-comment:before {
  content: '\e812'; }

.icon-chat:before {
  content: '\e813'; }

.icon-plus-squared:before {
  content: '\e814'; }

.icon-phone:before {
  content: '\e815'; }

.icon-reply:before {
  content: '\e816'; }

.icon-reply-all:before {
  content: '\e817'; }

.icon-forward:before, .icon-imgpreland:before {
  content: '\e818'; }

.icon-quote:before {
  content: '\e819'; }

.icon-pencil:before {
  content: '\e81a'; }

.icon-check:before {
  content: '\e81c'; }

.icon-cancel:before {
  content: '\e81d'; }

.icon-gauge:before {
  content: '\e81e'; }

.icon-cancel-squared:before {
  content: '\e81f'; }

.icon-mail:before {
  content: '\e820'; }

.icon-vcard:before {
  content: '\e821'; }

.icon-block:before {
  content: '\e823'; }

.icon-resize-full-1:before {
  content: '\e824'; }

.icon-arrow-combo:before {
  content: '\e825'; }

.icon-down-dir:before {
  content: '\e826'; }

.icon-left-dir:before {
  content: '\e827'; }

.icon-right-dir:before {
  content: '\e828'; }

.icon-up-dir:before {
  content: '\e829'; }

.icon-play:before {
  content: '\e82a'; }

.icon-stop:before {
  content: '\e82b'; }

.icon-pause:before {
  content: '\e82c'; }

.icon-to-end:before {
  content: '\e82d'; }

.icon-to-start:before {
  content: '\e82e'; }

.icon-fast-forward:before {
  content: '\e82f'; }

.icon-fast-backward:before {
  content: '\e830'; }

.icon-globe:before {
  content: '\e831'; }

.icon-eye:before {
  content: '\e832'; }

.icon-chart-pie:before {
  content: '\e833'; }

.icon-chart-line:before {
  content: '\e834'; }

.icon-chart-bar:before {
  content: '\e835'; }

.icon-chart-area:before {
  content: '\e836'; }

.icon-floppy:before {
  content: '\e837'; }

.icon-clipboard:before {
  content: '\e838'; }

.icon-share:before {
  content: '\e83d'; }

.icon-thumbs-up:before {
  content: '\e83e'; }

.icon-thumbs-down:before {
  content: '\e83f'; }

.icon-info:before {
  content: '\e840'; }

.icon-help:before {
  content: '\e841'; }

.icon-link:before, .icon-imgbanner:before {
  content: '\e842'; }

.icon-lock:before {
  content: '\e843'; }

.icon-lock-open:before {
  content: '\e844'; }

.icon-plus:before {
  content: '\e845'; }

.icon-minus:before {
  content: '\e846'; }

.icon-trash:before {
  content: '\e847'; }

.icon-attention:before {
  content: '\e849'; }

.icon-rss:before {
  content: '\e84a'; }

.icon-record:before {
  content: '\e84b'; }

.icon-target:before {
  content: '\e84c'; }

.icon-list:before {
  content: '\e84d'; }

.icon-signal:before {
  content: '\e84e'; }

.icon-mobile:before {
  content: '\e84f'; }

.icon-monitor:before {
  content: '\e850'; }

.icon-tablet:before {
  content: '\e822'; }

.icon-credit-card:before, .icon-imglanding:before {
  content: '\e851'; }

.icon-menu:before {
  content: '\e852'; }

.icon-minus-squared:before {
  content: '\e854'; }

.icon-print:before {
  content: '\e855'; }

.icon-tag:before {
  content: '\e856'; }

.icon-bookmark:before {
  content: '\e857'; }

.icon-list-add:before {
  content: '\e858'; }

.icon-network:before {
  content: '\e859'; }

.icon-calendar:before {
  content: '\e85a'; }

.icon-home:before {
  content: '\e85b'; }

.icon-camera:before {
  content: '\e85c'; }

.icon-ccw:before {
  content: '\e85e'; }

.icon-cw:before {
  content: '\e85f'; }

.icon-video:before {
  content: '\e860'; }

.icon-picture:before {
  content: '\e861'; }

.icon-flag:before {
  content: '\e863'; }

.icon-layout:before {
  content: '\e864'; }

.icon-resize-full:before {
  content: '\e865'; }

.icon-resize-small:before {
  content: '\e866'; }

.icon-resize-small-1:before {
  content: '\e867'; }

.icon-resize-vertical:before {
  content: '\e868'; }

.icon-resize-horizontal:before {
  content: '\e869'; }

.icon-move:before {
  content: '\e86a'; }

.icon-popup:before {
  content: '\e86b'; }

.icon-txt:before {
  content: '\e86e'; }

.icon-word:before {
  content: '\e839'; }

.icon-excel:before {
  content: '\e83a'; }

.icon-pdf:before {
  content: '\e81b'; }

.icon-file-doc:before, .icon-file-rtf:before {
  content: '\e839';
  color: #ddd;
  background-color: #295391; }

.icon-file-xls:before, .icon-file-csv:before {
  content: '\e83a';
  color: #ddd;
  background-color: #1f6b41; }

.icon-file-pdf:before {
  content: '\e81b';
  color: #ddd;
  background-color: #7b0000; }

.icon-file-ppt:before {
  content: '\e848';
  color: #ddd;
  background-color: #c44423; }

.icon-file-zip:before {
  content: '\e862';
  color: #fb0; }

.icon-file-htm:before {
  content: '\e86d';
  color: #1f6b41; }

.icon-file-txt:before, .icon-file-odp:before, .icon-file-ods:before, .icon-file-odt:before {
  content: '\e86e'; }

.icon-file-image:before {
  content: '\e86c'; }

.icon-file-vid:before {
  content: '\e86f'; }

.icon-file-video:before {
  content: '\e86f'; }

.icon-file-audio:before {
  content: '\e870'; }

html, body {
  width: 100%;
  height: 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 87.5%;
  padding: 0;
  margin: 0; }
  @media (min-width: 56em) {
    html, body {
      font-size: 93.75%; } }
  @media (min-width: 70em) {
    html, body {
      font-size: 100%; } }

html {
  touch-action: manipulation; }

body {
  line-height: 1.3;
  padding: 5em 0;
  overflow-y: scroll; }

table, input, output, textarea, select, datalist, option, pre, button {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  font-weight: 300; }

pre {
  font-family: Consolas, Menlo, "DejaVu Mono", monospace; }

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 600;
  padding: 0;
  margin: 0; }

h1 {
  width: 100%;
  font-size: 1.75em;
  font-weight: 300;
  margin-bottom: 0.2em; }
  h1 span {
    display: block;
    font-size: 0.75em; }
  header h1 {
    line-height: 1.75;
    margin-left: 2rem; }

h2 {
  font-size: 1.25em; }
  h2 span {
    display: block; }

h3 {
  font-size: 1em; }

p {
  margin: 0 0 1em 0; }

ul {
  list-style-type: disc; }
  ul ul {
    list-style-type: square; }

em {
  font-style: normal; }

hr {
  height: 1px;
  border: 0 none;
  margin: 8px 0; }

a {
  text-decoration: none; }

img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0;
  margin: 0;
  user-select: none; }

#report form > h2 {
  margin-top: 3em;
  margin-bottom: 0.2em; }

article h2 {
  margin-top: 3em; }

article li {
  margin-bottom: 0.5em; }

article img {
  margin: 1em 0; }

main {
  position: relative;
  padding: 0 2em 5em 2em;
  margin: 0 0 0 3em;
  z-index: 0; }
  @media (min-width: 56em) {
    main {
      margin-left: 3em; } }
  @media (min-width: 70em) {
    main {
      margin-left: 9em; } }

body.full {
  padding: 0;
  overflow: hidden; }
  body.full main {
    position: absolute;
    top: 3em;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0; }

article {
  max-width: 40em; }

iframe.full {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border: 0 none;
  overflow: auto; }

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 3em;
  padding-bottom: 10px; }
  footer p {
    display: none;
    font-size: 0.8em;
    text-align: center;
    padding: 0;
    margin: 0; }
  @media (min-width: 56em) {
    footer {
      width: 3em; } }
  @media (min-width: 70em) {
    footer {
      width: 9em; }
      footer p {
        display: block; } }

nav.menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3em; }
  nav.menu ul, nav.menu li {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  nav.menu a {
    display: block;
    text-transform: uppercase;
    line-height: 3em;
    white-space: nowrap;
    overflow: hidden;
    outline: 0 none; }
    nav.menu a:before {
      width: 3em;
      margin: 0; }
  nav.menu .home a {
    height: 3em;
    padding: 0 2px;
    margin-bottom: 1em;
    overflow: hidden;
    outline: 0 none; }
  @media (min-width: 56em) {
    nav.menu {
      width: 3em; } }
  @media (min-width: 70em) {
    nav.menu {
      width: 9em; } }

form {
  padding: 0;
  margin: 0; }
  form div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 2px 2em; }
    form div.tall {
      align-items: flex-start; }
      form div.tall p, form div.tall label {
        padding-top: 0.4em; }
    form div.issuecompany {
      align-items: flex-start; }
  form div.ggchart, form div.ggchart div {
    margin: 0; }
  form a.addlink {
    margin-left: 8em; }
  form nav ul, form ul.buttons {
    float: right;
    list-style-type: none;
    padding: 0;
    margin: 0; }
    form nav ul li, form ul.buttons li {
      display: inline-block;
      padding: 0;
      margin: 0 0 4px 4px; }
    form nav ul.alt, form ul.buttons.alt {
      float: left; }
      form nav ul.alt.pad, form ul.buttons.alt.pad {
        margin-left: 10em; }
      form nav ul.alt li, form ul.buttons.alt li {
        margin: 0 4px 4px 0; }
  form nav.login {
    padding: 0; }
  form nav p {
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0; }
    form nav p a {
      display: block;
      padding: 8px 0; }

fieldset {
  padding: 1em 0;
  margin: 0 0 1em 0;
  border: 0 none; }
  fieldset fieldset {
    padding: 0;
    margin: 0; }

legend {
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 1em 0; }

label {
  order: 1;
  width: 8em;
  text-align: right;
  padding-right: 1em;
  white-space: nowrap;
  user-select: none;
  cursor: pointer; }
  label.longlabel {
    white-space: inherit; }

input, output, textarea, select, pre {
  appearance: none;
  order: 2;
  flex: 2 1 auto;
  height: 2.25em;
  padding: 0.4em 0.6em;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  outline: 0 none;
  box-shadow: none; }

select {
  padding-left: 0.4em;
  outline: 0 none;
  cursor: pointer; }

textarea {
  height: auto; }
  textarea[disabled], textarea[readonly] {
    font-size: 0.85em;
    padding-top: 0;
    white-space: pre-wrap; }
  textarea#url_notes {
    min-height: 27px !important; }

pre {
  font-size: 0.85em;
  width: 10em;
  height: auto;
  padding-top: 0;
  margin-top: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: auto; }
  pre span.domain {
    font-weight: 600; }

input[type="file"] {
  height: auto;
  padding: 0.4em;
  cursor: pointer; }

input[type="checkbox"], input[type="radio"] {
  order: 1;
  flex: 0 0 auto;
  width: 2em;
  height: auto; }
  div input[type="checkbox"], div input[type="radio"] {
    margin-left: 8em; }
  div span.chkcol input[type="checkbox"], div span.chkcol input[type="radio"] {
    margin-left: 0; }
    div span.chkcol input[type="checkbox"] ~ label, div span.chkcol input[type="radio"] ~ label {
      width: 0; }
  input[type="checkbox"] ~ label, input[type="radio"] ~ label {
    position: relative;
    width: auto;
    left: -2em;
    text-align: left;
    padding-left: 0;
    padding-right: 0; }
    input[type="checkbox"] ~ label::before, input[type="checkbox"] ~ label::after, input[type="radio"] ~ label::before, input[type="radio"] ~ label::after {
      display: inline-block;
      font-family: "icons";
      content: '\e81d';
      width: 2.6em;
      vertical-align: top;
      text-indent: 1.7em;
      margin-right: 0.6em; }
    input[type="checkbox"] ~ label::after, input[type="radio"] ~ label::after {
      position: absolute;
      display: block;
      content: '';
      width: 1.1em;
      height: 80%;
      left: 0.15em;
      top: 10%;
      transform: translateX(0);
      transition: transform 70ms ease-in; }
  input[type="checkbox"]:checked ~ label::before, input[type="radio"]:checked ~ label::before {
    content: '\e81c';
    text-indent: 0.3em; }
  input[type="checkbox"]:checked ~ label::after, input[type="radio"]:checked ~ label::after {
    transform: translateX(1.25em); }

form div p {
  width: 8em;
  text-align: right;
  padding: 0 1em 0 0;
  margin: 0;
  user-select: none; }
  form div p ~ input[type="checkbox"], form div p ~ input[type="radio"] {
    margin-left: 0; }

form div .chkcol {
  width: 4.5em;
  text-align: left; }

form div span.chkcol {
  display: flex; }

form div ol, form div ul {
  order: 2;
  flex: 2 1 auto;
  padding: 0;
  margin: 0; }

form div li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
  margin: 0;
  margin: 0.1em 0; }
  form div li.template {
    display: none; }

form div .button {
  order: 3;
  width: 2.5em; }

button, .button {
  display: inline-block;
  width: 11.5em;
  height: 2.25em;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  line-height: 2.2;
  padding: 0;
  margin: 0;
  border: 0 none;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  user-select: none;
  cursor: pointer; }
  button::-moz-focus-inner, .button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  button span[class^="icon-"], button span[class*=" icon-"], .button span[class^="icon-"], .button span[class*=" icon-"] {
    padding-right: 0.6em; }
  button[disabled], .button[disabled] {
    cursor: default; }

#readonly form div.tall > p {
  padding-top: 0; }

#readonly form div.tall li {
  margin-top: 0; }

#readonly output {
  height: auto;
  padding: 0 0.6em 0 0; }

#readonly label {
  cursor: default; }

#readonly a.url {
  display: block;
  order: 2;
  max-width: 16em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

#readonly ol#media li figure {
  min-height: 3.5em; }

label.copyable.active:before {
  display: inline-block;
  visibility: hidden;
  font-family: 'icons';
  content: '\e838';
  padding-right: 0.25em;
  cursor: copy; }

label.copyable.active:hover:before {
  visibility: visible; }

#readonly label.copyable {
  cursor: copy; }

div.copier {
  display: block; }
  div.copier label.copyable {
    width: auto;
    text-align: left;
    margin-left: 8em; }

#filehover {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: "icons";
  font-size: 100vmin;
  line-height: 1;
  text-align: center;
  margin: auto; }

body.filehover #filehover {
  display: block; }

p.nodata {
  display: none;
  margin: 1em 0; }

.nodata p.nodata {
  display: block; }

.nodata table, .nodata nav.tablenav {
  display: none; }

.tablescroll {
  overflow-x: auto;
  margin: 0; }

table {
  width: 100%;
  font-size: 1em;
  font-variant-numeric: tabular-nums;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  empty-cells: show;
  user-select: none; }

form.active button.icon-search {
  display: none; }

form.active table {
  cursor: pointer; }

form.active th {
  cursor: ns-resize;
  user-select: none; }

form.active a {
  color: inherit !important; }

th, td {
  font-weight: 300;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3em;
  margin: 0;
  overflow: hidden; }
  th.num, th.date, td.num, td.date {
    width: 3.5em;
    text-align: right;
    direction: rtl;
    text-overflow: clip; }
  th.date, td.date {
    width: 6.2em; }
  th.ref, td.ref {
    width: 7.5em; }
  @media (min-width: 56em) {
    th.adflow, td.adflow {
      width: 9em; } }
  th:before, td:before {
    width: auto;
    margin: 0; }
  th[class^="icon"], td[class^="icon"] {
    width: 2.4em;
    text-overflow: clip;
    margin: 0; }
  th sup, td sup {
    font-size: 0.6em;
    opacity: 0.6; }

th {
  font-weight: 600;
  text-overflow: unset; }

td[rowspan] {
  vertical-align: top; }

tbody tr.rowtemplate, div.testcardtemplate {
  display: none; }

div.testcard {
  position: absolute;
  z-index: 9999;
  right: 20px;
  display: block;
  margin: 0;
  font-size: 0.7rem; }
  div.testcard time {
    float: right; }
  div.testcard p {
    text-align: left;
    width: unset;
    padding: 0.8rem; }
  div.testcard section.testcardbreach p {
    font-size: inherit;
    margin-bottom: 0;
    padding: 0; }
    div.testcard section.testcardbreach p:before {
      content: "\e823";
      display: inline-block;
      font-family: icons;
      text-align: center;
      width: 0.8em;
      color: #c00;
      padding-right: 1rem; }
  div.testcard div.subfields {
    padding: 0.8rem;
    margin: 0; }
    div.testcard div.subfields output[data-type="status"] {
      padding: 0; }
    div.testcard div.subfields fieldset {
      padding: 0.2rem; }
  div.testcard table.issue th {
    cursor: default;
    vertical-align: text-top; }
  div.testcard fieldset#testmedia {
    margin: 0; }
    div.testcard fieldset#testmedia ol#media li {
      max-width: 80px; }

table.info, table.help {
  table-layout: auto;
  margin-bottom: 1.5em; }
  table.info th, table.info td, table.help th, table.help td {
    white-space: normal;
    vertical-align: top; }

table.info th, table.info td {
  text-align: right;
  direction: rtl;
  text-overflow: clip; }

table.info th.left-align, table.info td.left-align {
  text-align: left;
  direction: ltr; }

table.help td:first-child {
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  padding-left: 2em;
  padding-right: 0.5em; }

table.issue {
  table-layout: auto;
  width: auto;
  margin: 0 0 1em 0; }
  table.issue#issueinfo {
    display: inline-block !important; }
  table.issue th, table.issue td {
    padding: 0 1em 0 0; }

nav.tablenav .sep {
  padding-left: 0.75em; }

nav.tablenav .pages {
  width: 3em; }

nav.tablenav button {
  width: 3em;
  margin-left: 0; }

col.status500 {
  background-color: rgba(136, 136, 255, 0.3); }

col.status1000 {
  background-color: rgba(136, 204, 102, 0.3); }

col.status1500 {
  background-color: rgba(187, 187, 204, 0.3); }

col.status2000 {
  background-color: rgba(255, 170, 102, 0.3); }

col.status3000 {
  background-color: rgba(221, 85, 85, 0.3); }

col.status4000 {
  background-color: rgba(255, 153, 153, 0.3); }

col.issue-state1 {
  background-color: rgba(187, 187, 204, 0.3); }

col.issue-state01 {
  background-color: rgba(136, 204, 102, 0.3); }

col.issue-state00 {
  background-color: rgba(221, 85, 85, 0.3); }

section {
  position: relative; }
  section h2 {
    font-size: 0.85em;
    padding: 2px 5px 3px 5px;
    margin: 5px; }
  section a.icon-floppy {
    visibility: hidden;
    float: right;
    width: 1.2em;
    white-space: nowrap;
    overflow: hidden; }
  section:hover a.icon-floppy {
    visibility: visible; }
  section span.icon-cancel {
    float: right;
    width: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer; }
  section ul, section ol, section li {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  section ul, section ol, section p:last-child {
    margin-bottom: 10px; }
  section p, section li {
    font-size: 1.25em;
    font-weight: 300;
    margin: 0 10px; }
  section table {
    width: auto;
    min-width: calc(100% - 10px);
    margin: 0 auto; }
  section a:link, section a:visited, section a:hover, section a:focus, section a:active {
    color: inherit !important; }
  section p.help {
    float: right;
    font-size: 0.75em; }
    section p.help a:before {
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5;
      margin: 0;
      border-radius: 50%; }
  section p.alert {
    float: none;
    font-size: 0.9em;
    text-align: center; }
    section p.alert a:before {
      margin-right: 0.2em; }
  section p.flash {
    transform-origin: 1.2em 50%;
    animation: flash 0.2s ease-in 1s alternate 6; }
  section hr:last-child {
    height: 0; }
  section .main {
    font-size: 3em;
    letter-spacing: -0.04em;
    line-height: 1;
    margin-top: 0.4em;
    margin-bottom: 0.2em; }
  section h2 + .main {
    margin-top: 0; }
  section .status500 {
    color: #88f; }
    section .status500 a:before {
      margin-right: 0.2em;
      content: '\e863'; }
  section .status1000 {
    color: #8c6; }
    section .status1000 a:before {
      margin-right: 0.2em;
      content: '\e863'; }
  section .status1500 {
    color: #bbc; }
    section .status1500 a:before {
      margin-right: 0.2em;
      content: '\e863'; }
  section .status2000 {
    color: #fa6; }
    section .status2000 a:before {
      margin-right: 0.2em;
      content: '\e863'; }
  section .status3000 {
    color: #d55; }
    section .status3000 a:before {
      margin-right: 0.2em;
      content: '\e863'; }
  section .status4000 {
    color: #f99; }
    section .status4000 a:before {
      margin-right: 0.2em;
      content: '\e863'; }
  section strong {
    float: right;
    padding-left: 1em; }
  section progress {
    position: absolute;
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    border: 0 none; }
  section ul.barchart {
    margin: 5px; }
  section a {
    display: block; }
  section p.secondary {
    margin-top: 0.25em;
    margin-bottom: 0.25em; }

ul.barchart {
  clear: both;
  list-style-type: none;
  padding: 1px;
  margin: 1em auto; }
  ul.barchart li {
    font-size: 1em;
    padding: 0 3px;
    margin: 0 0 1px 0; }
  ul.barchart li:last-child {
    margin: 0; }
  ul.barchart a {
    padding: 0; }
  ul.barchart strong {
    float: right;
    padding-left: 1em; }

svg {
  overflow: hidden; }

svg.piechart {
  display: block;
  max-width: 360px;
  margin: 0 auto; }
  svg.piechart text {
    font-size: 2.6em;
    font-weight: 600;
    text-anchor: end;
    dominant-baseline: middle;
    pointer-events: none;
    stroke-width: 0.15em;
    fill: #222; }
    @supports (paint-order: stroke) {
      svg.piechart text {
        stroke: rgba(255, 255, 255, 0.9);
        stroke-linecap: round;
        stroke-linejoin: round;
        paint-order: stroke;
        animation: textfade 1.5s linear; } }
  svg.piechart text.rev {
    text-anchor: start; }
  svg.piechart path, svg.piechart circle, svg.piechart ellipse {
    stroke-width: 1; }
    @supports (paint-order: stroke) {
      svg.piechart path, svg.piechart circle, svg.piechart ellipse {
        transform-origin: 50% 50%;
        animation: grow 1s ease-out; } }

svg.barchart text {
  font-size: 1em;
  text-anchor: end;
  dominant-baseline: middle; }

svg.barchart text.total {
  font-size: 0.8em;
  font-weight: 600;
  text-anchor: start; }
  @supports (paint-order: stroke) {
    svg.barchart text.total {
      animation: textfade 1.2s linear; } }

svg.barchart text.value {
  font-size: 0.8em;
  font-weight: 600;
  fill: rgba(0, 0, 0, 0.7);
  pointer-events: none; }
  @supports (paint-order: stroke) {
    svg.barchart text.value {
      animation: textfade 1.8s linear; } }

svg.barchart text.axism {
  font-size: 0.8em;
  text-anchor: start;
  dominant-baseline: hanging; }

svg.barchart line {
  stroke-width: 1;
  stroke-linecap: butt; }

svg.barchart line.axism {
  stroke-width: 0.5;
  stroke-dasharray: 1, 2; }

@supports (paint-order: stroke) {
  svg.barchart rect {
    animation: growX 1s ease-out; } }

svg + svg.piechart, svg + svg.barchart {
  margin-top: 1em; }

body#dashboard main section.announcements {
  display: none; }
  body#dashboard main section.announcements p {
    margin: 0; }
  body#dashboard main section.announcements li, body#dashboard main section.announcements p {
    font-size: 1em;
    margin-bottom: 1em; }
  body#dashboard main section.announcements li ul, body#dashboard main section.announcements li li {
    list-style-type: disc;
    margin: 1em 0 1em 1.8em; }
  body#dashboard main section.announcements li li {
    margin: 0.3em 0; }
  body#dashboard main section.announcements a h3:after {
    content: '\e818';
    display: inline-block;
    font-family: "icons";
    font-weight: normal;
    padding-left: 0.25em;
    transform: translateX(0) rotate(-30deg);
    transition: transform 0.2s ease; }
  body#dashboard main section.announcements a:hover h3:after, body#dashboard main section.announcements a:focus h3:after {
    transform: translateX(0.25em) rotate(0deg);
    animation: 1.5s ease-out 1s forwards infinite linkarrow; }

body#dashboard main p.announcements {
  display: block; }

body#dashboard.announcements-open main section.announcements {
  display: block; }

body#dashboard.announcements-open main p.announcements {
  display: none; }

body#dashboard main section.map {
  flex: 3 1 59em; }
  body#dashboard main section.map div {
    position: relative;
    margin: 0 4px;
    overflow: hidden; }

svg.map {
  width: 100%;
  min-height: 15em;
  text-anchor: middle;
  stroke-width: 1; }
  svg.map path {
    transition: fill 0.3s linear; }

ul.pin {
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  font-size: 0.7em;
  list-style-type: none;
  padding: 0;
  margin: 0;
  user-select: none; }
  ul.pin::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: calc(50% - 6px);
    bottom: -12px;
    border: 6px solid transparent;
    pointer-events: none; }
  ul.pin li {
    text-align: left;
    white-space: nowrap;
    line-height: 1.2;
    padding: 0;
    margin: 0; }
    ul.pin li a, ul.pin li span {
      padding: 0 3px; }
      ul.pin li a span, ul.pin li span span {
        float: right;
        padding: 0; }
    ul.pin li img {
      width: 130px;
      margin: 0 auto; }
    ul.pin li.title, ul.pin li.subtitle {
      font-weight: 600;
      padding: 2px 0; }
    ul.pin li.mi a:before {
      font-family: "icons";
      content: '\e833';
      font-weight: normal;
      display: inline-block;
      padding-right: 2px; }
    ul.pin li.ms a:before {
      font-family: "icons";
      content: '\e864';
      font-weight: normal;
      display: inline-block;
      padding-right: 2px; }

svg#load {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  svg#load circle {
    stroke: #ee750d;
    stroke-width: 4px;
    fill: transparent;
    transform-origin: 50%;
    animation: loadline 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, loadrotate 1.6s linear infinite; }
  svg#load.inactive {
    display: none; }

.cards ul.link li a span:after {
  content: '\e818';
  display: inline-block;
  font-family: "icons";
  font-weight: normal;
  padding-left: 0.25em;
  transform: translateX(0) rotate(-30deg);
  transition: transform 0.2s ease; }

.cards ul.link li a:hover span:after, .cards ul.link li a:focus span:after {
  transform: translateX(0.25em) rotate(0deg);
  animation: 1.5s ease-out 1s forwards infinite linkarrow; }

@keyframes flash {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.6); } }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes growX {
  0% {
    transform: scaleX(0); }
  100% {
    transform: scaleX(1); } }

@keyframes textfade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes linkarrow {
  0% {
    transform: translateX(0.25em); }
  15% {
    transform: translateX(0.5em); }
  30% {
    transform: translateX(0.25em); }
  100% {
    transform: translateX(0.25em); } }

@keyframes loadrotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(450deg); } }

@keyframes loadline {
  0% {
    opacity: 0;
    stroke-dasharray: 2, 86;
    transform: rotate(0); }
  50% {
    opacity: 1;
    stroke-dasharray: 66, 22;
    stroke-dashoffset: 0; }
  100% {
    stroke-dasharray: 2, 86;
    stroke-dashoffset: -66;
    transform: rotate(90deg); } }

.updated p {
  float: right;
  font-size: 0.85em;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 1.95;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.35s linear; }

.updated ol {
  position: absolute;
  font-size: 0.85em;
  right: 0;
  transform: translateX(100%);
  top: 4.5em;
  list-style-type: none;
  padding: 1em;
  margin: 0;
  transition: transform 0.35s ease; }
  .updated ol:before {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    top: -20px;
    right: 0.9em;
    border: 10px solid transparent; }

.updated img {
  width: 20px;
  margin: 0 5px 0 0;
  border-radius: 8%; }

.updated li[title] {
  cursor: help; }

.updated li.sep {
  padding-top: 0.25em;
  margin-top: 0.25em; }

.updated:hover ol {
  transform: translateX(-2em); }

#modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 9999;
  overflow: hidden;
  transition: height 0s linear 300ms; }
  #modal div {
    position: absolute;
    display: block;
    width: 25em;
    height: 18em;
    max-width: 90%;
    max-height: 90%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 3px 3px 3em 3px;
    margin: auto;
    border-radius: 3px;
    transform: translateY(-100vh);
    transition: transform 300ms cubic-bezier(0.7, -0.4, 1, 0); }
  #modal.progress div {
    padding-bottom: 3px;
    height: 4.5em; }
  #modal header {
    position: static;
    height: auto;
    font-weight: 600;
    padding: 3px 0.5em 5px 0.5em;
    border-radius: 3px; }
  #modal p {
    margin: 0.5em; }
  #modal progress {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 2em;
    margin: 5px 0;
    padding: 0;
    border-radius: 3px; }
  #modal #nav {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    list-style-type: none;
    padding: 0;
    margin: 0; }
    #modal #nav li {
      display: inline-block;
      margin: 0 0 0 4px; }
  #modal .button {
    width: 5em;
    height: 2em;
    line-height: 2; }
  #modal.active {
    height: 100%;
    transition-delay: 0s; }
    #modal.active div {
      transform: translateY(0vh);
      transition: transform 300ms cubic-bezier(0.6, 1.6, 0.8, 0.8); }

ol#media {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0.5em 2em; }
  ol#media.flows {
    justify-content: space-around; }
  ol#media li {
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0 10px 10px 0;
    overflow: hidden;
    z-index: 0; }
    ol#media li h3, ol#media li p {
      width: auto;
      text-align: left;
      padding: 0.5em 0.6em;
      margin: 0;
      user-select: none;
      word-break: break-all; }
    ol#media li h3 {
      cursor: pointer; }
    ol#media li p {
      padding: 0.25em 0.6em;
      user-select: text; }
    ol#media li figure {
      display: block;
      width: 100%;
      min-height: 4.75em;
      max-height: 32em;
      padding: 0;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto; }
    ol#media li img {
      max-width: 100%;
      max-width: calc(100% - 2px);
      margin: 1px auto;
      cursor: pointer; }
    ol#media li span {
      display: block;
      margin: 8px 0.5em 2px 0.5em; }
    ol#media li span:before {
      font-size: 2em;
      width: 1em;
      vertical-align: middle; }
    ol#media li fieldset {
      padding: 0;
      margin: 0; }
    ol#media li div {
      margin-left: 0;
      margin-right: 0; }
    ol#media li div.actions {
      display: none;
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      margin: 0; }
      ol#media li div.actions .button {
        display: block;
        clear: both;
        margin: 0 0 2px 0;
        opacity: 0.4; }
    ol#media li:hover div.actions .button, ol#media li:focus div.actions .button {
      opacity: 0.85; }
    @media (min-width: 30em) {
      ol#media li {
        max-width: 47%; } }
    @media (min-width: 56em) {
      ol#media li {
        max-width: 18em; } }
  ol#media.active div.actions {
    display: block; }
  ol#media.active div.actions .move {
    display: none;
    cursor: move; }
  ol#media.active li:hover div.actions .move {
    display: block; }
  ol#media.dragging div.actions {
    display: none; }
  ol#media li.dragging {
    pointer-events: none;
    opacity: 0.95;
    z-index: 1;
    transform: rotate(-1.5deg) scale(0.85);
    transition: transform 0.2s ease; }

#preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  outline: 0 none; }
  #preview.active {
    height: 100%; }
  #preview div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3.6em;
    right: 3.6em;
    overflow: hidden; }
  #preview img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: zoom-in; }
    #preview img.zoom {
      max-width: none;
      max-height: none; }
  #preview article {
    max-width: 30em;
    max-height: 90%;
    padding: 0.8em; }
  #preview p {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding: 0.8em 0.5em;
    margin: 0;
    transform: translateY(0);
    transition: transform 0.3s ease 0.6s; }
  #preview div:hover p {
    transform: translateY(100%); }
  #preview a {
    position: absolute;
    font-size: 3em;
    width: 1.2em;
    height: 100%;
    left: 0;
    top: 0; }
    #preview a:after {
      position: absolute;
      font-family: "icons";
      content: '\e805';
      width: 100%;
      height: 1em;
      line-height: 1;
      top: 0;
      bottom: 0;
      text-align: center;
      margin: auto; }
  #preview a.next {
    left: auto;
    right: 0; }
    #preview a.next:after {
      content: '\e806'; }
  #preview a.close {
    height: 1.25em;
    left: auto;
    right: 0; }
    #preview a.close:after {
      content: '\e81d'; }

button.active span:before, .button.active span:before {
  content: '\e808';
  animation: spin 1s linear infinite; }

#testissues {
  visibility: hidden; }
  #testissues.active {
    visibility: visible; }
  #testissues label {
    white-space: inherit; }

#testissues p.showhide {
  clear: right;
  float: right;
  font-size: 0.85em;
  margin: 1em 0;
  cursor: pointer; }

#issuelink {
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0 2em 1em 2em; }
  #issuelink li {
    text-indent: -1em; }

#assign {
  margin-bottom: 0; }

ul#commentbuttons.buttons.alt.pad {
  margin-left: 8em; }

#comments {
  width: 10em;
  max-height: 70vh;
  list-style-type: none;
  padding: 0 0.5em;
  margin: 0;
  text-overflow: clip;
  overflow-x: hidden;
  overflow-y: auto; }
  #comments li {
    padding: 0;
    margin: 0; }
    #comments li blockquote.speech.admin {
      margin-right: 2em; }
    #comments li blockquote.speech.client {
      margin-left: 2em; }
    #comments li.animate blockquote.speech {
      transform: scaleY(0);
      opacity: 0;
      transition: transform 0.3s cubic-bezier(0.6, 1, 0.2, 1.5); }
    #comments li.animate.in blockquote.speech {
      opacity: 1;
      transform: scaleY(1); }

blockquote.speech {
  width: 100%;
  font-size: 1rem;
  padding: 0;
  margin: 0 0 0.5em 0;
  text-overflow: clip;
  overflow: hidden; }
  blockquote.speech strong {
    display: block; }
  blockquote.speech p, blockquote.speech cite {
    width: auto;
    text-align: left;
    font-size: 1em;
    padding: 0;
    margin: 0; }
  blockquote.speech p {
    position: relative;
    display: block;
    font-size: 1em;
    line-height: 1.1;
    padding: 0.5em 0.75em;
    margin: 0 0 10px 0;
    white-space: pre-wrap;
    border-radius: 5px; }
  blockquote.speech p:after {
    display: block;
    position: absolute;
    left: 0.5em;
    bottom: -9px;
    content: ' ';
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid; }
  blockquote.speech cite {
    display: block;
    font-size: 0.9em;
    font-style: normal;
    line-height: 1.2;
    padding: 0 32px; }
  blockquote.speech img {
    float: left;
    max-width: 20px;
    margin-left: -24px;
    border-radius: 20%; }
  blockquote.speech a {
    outline: 0 none;
    text-overflow: clip; }
    blockquote.speech a:hover, blockquote.speech a:focus {
      text-decoration: underline; }
  blockquote.speech.admin {
    margin-right: 2em; }
  blockquote.speech.client p:after {
    left: auto;
    right: 0.5em; }
  blockquote.speech.client cite {
    text-align: right; }
  blockquote.speech.client img {
    float: right;
    margin-left: 0;
    margin-right: -24px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.linked {
  text-align: center;
  padding: 0.5em;
  margin: 0 0 1em 0; }
  .linked img, .linked video {
    max-width: 40vh;
    max-height: 40vh;
    margin: 0.5em auto 0; }

.cards {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .cards > li {
    display: inline-block;
    width: 20em;
    position: relative;
    vertical-align: top;
    padding: 0 0 3.5em 0;
    margin: 0 1.5vw 1.5vw 0; }
    .cards > li.reveal {
      animation: cardreveal 1.2s ease; }
  .cards figure {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto; }
  .cards img {
    margin: 0 auto;
    cursor: pointer; }
  .cards .merchant {
    display: block;
    font-weight: 600; }
  .cards .new {
    margin: 0; }
  .cards .highlight {
    font-weight: 600;
    text-align: center;
    padding: 0.25em 0.5em; }
  .cards .tests, .cards ul {
    padding: 0.25em 0.5em;
    margin: 0; }
  .cards time {
    float: right; }
  .cards ul.none {
    display: none; }
  .cards ul li {
    display: inline-block;
    padding: 0.1em 1em 0.1em 0; }
  .cards ul.link {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0; }
    .cards ul.link li {
      padding: 1em;
      margin: 0;
      font-weight: 600; }

@supports (display: grid) {
  .cards {
    margin-top: 1em;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    grid-auto-rows: minmax(20em, auto);
    grid-gap: 1.5vw; }
    .cards > li {
      width: auto;
      margin: 0; }
    @media (min-width: 56em) {
      .cards .col2 {
        grid-column-end: span 2; }
      .cards .row2 {
        grid-row-end: span 2; }
        .cards .row2 figure {
          max-height: 64vh; } } }

@keyframes cardreveal {
  0% {
    transform: translateY(4em);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

body#dashboard {
  padding: 1em; }
  body#dashboard header, body#dashboard main {
    position: static;
    display: block;
    width: auto;
    min-width: 18.5em;
    height: auto;
    padding: 0;
    margin: 0 auto; }
  body#dashboard main {
    padding-bottom: 1em; }
  body#dashboard header div {
    margin: 5px;
    overflow: hidden; }
  body#dashboard header h1 {
    float: left;
    font-size: 1em;
    width: 12em;
    height: 3.5em;
    padding: 0.7em 2em 0 1em;
    margin: 0;
    background-color: #ee7207;
    border-radius: 0 0 3em 0; }
    body#dashboard header h1 path {
      fill: #000;
      stroke-width: 0; }
      body#dashboard header h1 path.em, body#dashboard header h1 path.dot {
        fill: #fff; }
  body#dashboard header .logobig {
    float: right;
    width: 9em;
    max-height: 3.5em;
    margin: 0 1em; }
  body#dashboard header p {
    font-size: 1.25em;
    font-weight: 600;
    text-align: center;
    padding: 0 140px;
    margin: 0.7em 0 0 0; }
  body#dashboard p.system {
    font-size: 1.5em;
    text-align: center;
    margin: 1em auto; }
    body#dashboard p.system::before {
      animation: spin 5s linear infinite; }

body#dashboard main, body#dashboard main div.sectiongroup, body#report main, body#report main div.sectiongroup {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

body#dashboard main.sectiongroup, body#report main.sectiongroup {
  display: block; }

body#dashboard main.restricted, body#report main.restricted {
  max-width: 1200px; }

body#dashboard main.notablereport, body#report main.notablereport {
  flex-direction: column; }

body#dashboard main section, body#dashboard main article, body#report main section, body#report main article {
  flex: 1 1 17em;
  padding: 0 0 5px 0;
  margin: 5px; }
  body#dashboard main section div, body#dashboard main article div, body#report main section div, body#report main article div {
    flex-wrap: wrap;
    margin: 5px; }
  body#dashboard main section button, body#dashboard main article button, body#report main section button, body#report main article button {
    order: 8;
    width: auto;
    padding-left: 0.5em;
    padding-right: 0.5em; }
  body#dashboard main section p.message, body#dashboard main article p.message, body#report main section p.message, body#report main article p.message {
    order: 9;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0.5em; }
    body#dashboard main section p.message a, body#dashboard main article p.message a, body#report main section p.message a, body#report main article p.message a {
      color: #ee7207 !important; }
  body#dashboard main section p#themetoggler, body#dashboard main section p#themetoggler_demo, body#dashboard main article p#themetoggler, body#dashboard main article p#themetoggler_demo, body#report main section p#themetoggler, body#report main section p#themetoggler_demo, body#report main article p#themetoggler, body#report main article p#themetoggler_demo {
    cursor: pointer; }

body#dashboard main aside.note, body#report main aside.note {
  width: 75%;
  text-align: left;
  margin: 1.5em auto;
  padding: 0.5em 2em; }
  body#dashboard main aside.note p, body#report main aside.note p {
    margin-bottom: 0; }

body#dashboard main div#files h2, body#dashboard main div#reports h2, body#dashboard main div#flows h2, body#report main div#files h2, body#report main div#reports h2, body#report main div#flows h2 {
  margin-top: 3em;
  margin-bottom: 1em; }

body#dashboard main div#files ul, body#dashboard main div#reports ul, body#dashboard main div#flows ul, body#report main div#files ul, body#report main div#reports ul, body#report main div#flows ul {
  list-style-type: none;
  padding: 0 0 1em 0;
  max-width: 300px;
  margin-top: 2em; }
  body#dashboard main div#files ul.files, body#dashboard main div#reports ul.files, body#dashboard main div#flows ul.files, body#report main div#files ul.files, body#report main div#reports ul.files, body#report main div#flows ul.files {
    max-width: 400px; }
  body#dashboard main div#files ul li, body#dashboard main div#reports ul li, body#dashboard main div#flows ul li, body#report main div#files ul li, body#report main div#reports ul li, body#report main div#flows ul li {
    margin-bottom: 0.5em; }
    body#dashboard main div#files ul li button, body#dashboard main div#reports ul li button, body#dashboard main div#flows ul li button, body#report main div#files ul li button, body#report main div#reports ul li button, body#report main div#flows ul li button {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      body#dashboard main div#files ul li button span, body#dashboard main div#reports ul li button span, body#dashboard main div#flows ul li button span, body#report main div#files ul li button span, body#report main div#reports ul li button span, body#report main div#flows ul li button span {
        padding-left: 1em; }
      body#dashboard main div#files ul li button span:after, body#dashboard main div#reports ul li button span:after, body#dashboard main div#flows ul li button span:after, body#report main div#files ul li button span:after, body#report main div#reports ul li button span:after, body#report main div#flows ul li button span:after {
        content: '\e837';
        visibility: hidden;
        display: inline-block;
        font-family: icons;
        font-weight: 400;
        padding-left: 0.5em; }
    body#dashboard main div#files ul li button:hover span:after, body#dashboard main div#reports ul li button:hover span:after, body#dashboard main div#flows ul li button:hover span:after, body#report main div#files ul li button:hover span:after, body#report main div#reports ul li button:hover span:after, body#report main div#flows ul li button:hover span:after {
      visibility: visible; }

body#dashboard main div#contact a:after, body#report main div#contact a:after {
  content: '\e820';
  display: inline-block;
  font-family: icons;
  font-weight: 400;
  padding-left: 0.5em; }

body#report main section {
  flex-basis: 80%; }
  body#report main section.wide {
    flex-basis: 100%; }
  @media (min-width: 30em) {
    body#report main section {
      flex-basis: 40%; } }
  @media (min-width: 56em) {
    body#report main section {
      flex-basis: 21%; } }

#filter div.filters {
  flex-wrap: nowrap;
  justify-content: left;
  padding: 0; }
  #filter div.filters.wrap {
    flex-wrap: wrap; }
  #filter div.filters:focus-within {
    flex-wrap: wrap; }

div.filters {
  clear: both;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.2em 0;
  margin: 0; }
  div.filters div, div.filters input, div.filters select, div.filters button, div.filters #published {
    flex: 1 1 auto;
    order: 1;
    width: auto;
    min-width: 3em;
    margin: 0.2em 0.25em; }
  div.filters button {
    flex: 0 0 auto;
    width: 3em;
    min-width: auto;
    margin-left: auto; }
    div.filters button#submit-report {
      order: 0;
      margin-left: unset; }
  div.filters input[type="date"], div.filters #month {
    max-width: 9.25em; }
  div.filters input[type="year"], div.filters input[type="number"], div.filters #ps {
    max-width: 4.5em; }
  div.filters input.fitcontent, div.filters select.fitcontent {
    max-width: fit-content; }
  div.filters #period {
    max-width: 5em; }
  div.filters #country {
    max-width: 9em; }
  div.filters #gm, div.filters #complete, div.filters #active {
    flex: 1 0 auto;
    width: 5.5em; }
  div.filters #gm {
    font-weight: 600; }
  div.filters div {
    flex: 0 0 auto; }
    div.filters div input[type="checkbox"], div.filters div input[type="radio"] {
      width: 2em;
      min-width: auto;
      padding: 0; }
      div.filters div input[type="checkbox"]:focus ~ label, div.filters div input[type="radio"]:focus ~ label {
        font-weight: 300; }

div.sepfilters {
  padding: 0;
  margin-bottom: 1em; }
  div.sepfilters:last-of-type {
    margin-bottom: 3em; }

div.advanced {
  position: relative;
  top: -2em;
  height: 0;
  transform: scaleY(0);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  transform-origin: 50% 0;
  transition: transform 0.2s ease-in-out; }
  div.advanced > fieldset {
    flex: 1 1 auto;
    padding: 0.2em 0;
    margin: 0; }
  div.advanced fieldset div {
    margin: 2px 0.8em; }
  div.advanced fieldset label {
    width: 8.2em; }

.advanced-open div.advanced {
  height: auto;
  transform: scaleY(1); }

.advanced-open button.toggler.icon-menu::before {
  content: '\e807'; }

body th.status0, body td.status0, body output.status0 {
  color: inherit; }
  body th.status0:before, body td.status0:before, body output.status0:before {
    content: '\e863'; }

body th.status500, body td.status500, body output.status500 {
  color: #88f; }
  body th.status500:before, body td.status500:before, body output.status500:before {
    content: '\e863'; }

body th.status1000, body td.status1000, body output.status1000 {
  color: #8c6; }
  body th.status1000:before, body td.status1000:before, body output.status1000:before {
    content: '\e863'; }

body th.status1500, body td.status1500, body output.status1500 {
  color: #bbc; }
  body th.status1500:before, body td.status1500:before, body output.status1500:before {
    content: '\e863'; }

body th.status2000, body td.status2000, body output.status2000 {
  color: #fa6; }
  body th.status2000:before, body td.status2000:before, body output.status2000:before {
    content: '\e863'; }

body th.status3000, body td.status3000, body output.status3000 {
  color: #d55; }
  body th.status3000:before, body td.status3000:before, body output.status3000:before {
    content: '\e863'; }

body th.status4000, body td.status4000, body output.status4000 {
  color: #f99; }
  body th.status4000:before, body td.status4000:before, body output.status4000:before {
    content: '\e863'; }

td.published:before {
  content: ""; }

td.active:before {
  content: ""; }

td.marketing:before {
  content: ""; }

td.payment:before {
  content: ""; }

td.careline:before {
  content: ""; }

td.cancomment:before {
  content: ""; }

td.canfix:before {
  content: ""; }

td.canverify:before {
  content: ""; }

td.canedit:before {
  content: ""; }

#teststatus {
  display: flex;
  flex-wrap: nowrap;
  align-items: center; }
  #teststatus div {
    margin: 0 0.25em; }
    #teststatus div.ref {
      flex: 1 2 auto; }

#testservice div.novisible {
  visibility: hidden; }

#dupissuemessage {
  margin: 0 auto 1em; }
  #dupissuemessage div {
    padding: .5em;
    font-size: medium;
    font-weight: 400;
    margin: 0 auto; }

li.new:before {
  display: inline-block;
  font-family: "icons";
  content: '\e849';
  width: 0.75em;
  cursor: help; }

#ref {
  width: 100%;
  font-size: 1.25em;
  font-weight: 600; }

input#ref {
  height: 1.85em; }

#status {
  font-weight: 600; }

output#status {
  padding: 0; }
  output#status:before {
    margin-right: 0.25em; }

input#smscode, input#smskeyword {
  max-width: 10em; }

.subfields {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.8em 0;
  margin: 0 0 1em 0; }
  .subfields fieldset {
    flex: 1 1 auto;
    padding: 0.2em 0;
    margin: 0;
    overflow: hidden; }
    @media (min-width: 56em) {
      .subfields fieldset {
        flex-basis: 50%; } }

.assign {
  margin-bottom: 0;
  padding-bottom: 0; }

.hide {
  display: none; }

#targetdiv {
  display: block; }

#testissues {
  padding: 1em 2em; }
  #testissues div {
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
    break-inside: avoid; }
    #testissues div#contact {
      display: block;
      float: right; }
      #testissues div#contact .button {
        width: 100%; }
      #testissues div#contact p {
        text-align: left;
        padding: 0; }
  #testissues p {
    display: block;
    font-size: 1em;
    margin: 0 0 0.75em 0;
    break-inside: avoid;
    user-select: text; }
  #testissues section.cols {
    column-gap: 0;
    margin-left: 8em;
    margin-bottom: 0.5em; }
    #testissues section.cols input[type="checkbox"] {
      margin-left: 0; }
    #testissues section.cols label {
      white-space: nowrap; }
    @media (min-width: 56em) {
      #testissues section.cols {
        column-count: 2; }
        #testissues section.cols p {
          display: inline-block; } }

#readonly #testissues section {
  column-gap: 2em;
  margin: 0 0 1em 0; }
  @media (min-width: 70em) {
    #readonly #testissues section {
      column-count: 1; } }
  #readonly #testissues section p {
    padding-left: 1.4em;
    text-indent: -0.8em; }
    #readonly #testissues section p::before {
      font-family: 'icons';
      content: '\e823';
      display: inline-block;
      width: 0.8em;
      text-align: center; }

#userstatus {
  display: flex;
  flex-wrap: nowrap;
  align-items: center; }
  #userstatus div {
    margin: 0 0.25em; }
    #userstatus div.name {
      flex: 10 1 auto; }
    #userstatus div.active {
      flex: 0 0 9em;
      margin-right: -1.5em; }
      #userstatus div.active input {
        margin-left: 0; }
      #userstatus div.active label {
        width: auto; }
    #userstatus div.expire input {
      width: 10.5em; }
    #userstatus div.expire label {
      width: 3em; }

#name {
  width: 100%;
  font-size: 1.25em;
  font-weight: 600; }

#token {
  max-width: 8em;
  overflow: hidden;
  text-overflow: ellipsis; }

table.report {
  table-layout: fixed; }
  table.report th, table.report td {
    width: 3em;
    text-align: right;
    direction: rtl;
    padding: 1px 2px;
    text-overflow: clip; }
    table.report th a, table.report td a {
      display: block; }
    table.report th.title, table.report td.title {
      width: auto;
      text-align: left;
      direction: ltr;
      text-overflow: ellipsis; }
    table.report th.wider, table.report td.wider {
      width: 5.5rem; }
    table.report th.nodata, table.report td.nodata {
      text-align: center; }
  table.report th {
    vertical-align: bottom; }
    table.report th span {
      padding-top: 4px;
      writing-mode: vertical-rl; }
  table.report[data-linkurl] td {
    cursor: pointer; }
    table.report[data-linkurl] td.z {
      cursor: auto; }

table.ops th, table.ops td {
  padding: 0 4px; }

table.ops td span {
  float: left; }

table.ops th.date {
  width: 6em; }

table.ops th.tests {
  width: auto; }

table.ops th.quality {
  width: 4.25em; }

table.ops .q {
  font-size: 1.85em; }

a.report-help {
  font-size: 0.5em;
  vertical-align: super; }

ul.downloads {
  float: right;
  list-style-type: none;
  padding: 0;
  margin: 0.5em 0 0.5em 1em; }

p.reportmessage {
  text-align: center;
  margin: 1.5em auto; }

body#report div.chartwrapper {
  max-height: 800px;
  overflow-y: auto;
  display: block; }

body#report form > svg {
  margin-top: 1em;
  margin-bottom: 1em; }

@media print {
  html, body {
    width: 100%;
    font-size: 14px;
    color: #000;
    background-color: #fff; }
  body#dashboard header, nav, button, a.button, footer, #filter, div.filters, div.advanced, a.report-help, ul.downloads, div.updated, div.copier, #testissues p.showhide {
    display: none !important; }
  main, .sectiongroup, .cards, .tablescroll {
    display: block !important; }
  body, main, h1 {
    padding: 0;
    margin: 0; }
  h1 {
    width: 100%;
    float: left; }
    header h1 {
      margin-left: 0; }
  a:link, a:visited {
    color: #000 !important; }
  article {
    min-width: 100%; }
  header, #teststatus, #userstatus {
    position: static; }
  table, col, tr, th, td, fieldset, div.subfields {
    border-color: #999 !important;
    page-break-inside: avoid;
    break-inside: avoid; }
  table {
    width: auto;
    min-width: auto !important;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
  section {
    border: 0 none; }
  #comments {
    height: auto;
    max-height: none;
    border: 0 none !important; }
  blockquote.speech p {
    border: 1px solid #999; }
  blockquote.speech p:after {
    border-color: #999 #999 transparent transparent !important; }
  blockquote.speech.client p:after {
    border-color: #999 transparent transparent #999 !important; }
  ol#media {
    margin: 0; }
    ol#media li {
      max-width: calc(33.3% - 12px);
      border-color: #999 !important; }
      ol#media li figure {
        overflow: hidden; }
  #dashboard section, #report section {
    float: left;
    width: 48%;
    margin: 1em 1%;
    border: 2px solid #999;
    page-break-inside: avoid;
    break-inside: avoid; }
    #dashboard section.wide, #report section.wide {
      float: none;
      width: 100%;
      margin: 1em auto; }
    #dashboard section h2, #report section h2 {
      padding: 0 0 5px 0;
      border-bottom: 1px solid #999; }
  .sectiongroup {
    clear: both; }
  .cards > li {
    display: inline-block;
    width: 31.5%;
    padding: 0;
    margin: 0 1% 1% 0;
    border: 2px solid #999; }
    .cards > li figure {
      max-height: none;
      overflow-y: hidden;
      border-top: 0 none !important;
      border-bottom: 1px solid #999; }
    .cards > li .link {
      display: none; }
  svg {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    page-break-inside: avoid;
    break-inside: avoid; }
  svg.piechart {
    max-width: 180px; }
  text {
    fill: #000 !important;
    stroke-width: 0 !important; }
  path {
    stroke: #fff !important; }
  circle, ellipse {
    stroke: #fff !important; }
    circle.inner, ellipse.inner {
      fill: #fff !important; } }
