// media control
ol#media {

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	list-style-type: none;
	padding: 0;
	margin: 0.5em 2em;

	&.flows {
		justify-content: space-around;
	}

  li {

		flex: 1 1 auto;
		width: 100%;
		position: relative;
		padding: 0;
		margin: 0 10px 10px 0;
		overflow: hidden;
		z-index: 0;

		h3, p {
			width: auto;
			text-align: left;
			padding: 0.5em 0.6em;
			margin: 0;
			user-select: none;
			word-break: break-all;
		}

		h3 {
			cursor: pointer;
		}

		p {
			padding: 0.25em 0.6em;
			user-select: text;
		}

    figure {
      display: block;
			width: 100%;
			min-height: 4.75em;
			max-height: 32em;
			@include reset;
			overflow-x: hidden;
			overflow-y: auto;
    }

    img {
			max-width: 100%;
			max-width: calc(100% - 2px);
			margin: 1px auto;
			cursor: pointer;
    }

		span {
			display: block;
			margin: 8px 0.5em 2px 0.5em;
		}

		span:before {
			font-size: 2em;
			width: 1em;
			vertical-align: middle;
		}

		fieldset {
			@include reset;
		}

		div {
			margin-left: 0;
			margin-right: 0;
		}

		div.actions {
			display: none;
			position: absolute;
			left: auto;
			right: 0;
			top: 0;
			margin: 0;

			.button {
				display: block;
				clear: both;
				margin: 0 0 2px 0;
				opacity: 0.4;
			}

		}

		&:hover, &:focus {
			div.actions .button { opacity: 0.85; }
		}

		@include respond('small') {
			max-width: 47%;
		}

		@include respond('medium') {
			max-width: 18em;
		}

  }

	&.active {

		div.actions {
			display: block;
		}

		div.actions .move {
			display: none;
			cursor: move;
		}

		li:hover div.actions .move {
			display: block;
		}

	}

	&.dragging div.actions {
		display: none;
	}

	li.dragging {
		pointer-events: none;
		opacity: 0.95;
		z-index: 1;
		transform: rotate(-1.5deg) scale(0.85);
		transition: transform 0.2s ease;
	}

}
