// print styling
@media print {

	html, body {
		width: 100%;
		font-size: 14px;
		color: #000;
		background-color: #fff;
	}

	body#dashboard header, nav, button, a.button, footer, #filter, div.filters, div.advanced, a.report-help, ul.downloads, div.updated, div.copier, #testissues p.showhide {
		display: none !important;
	}

	main, .sectiongroup, .cards, .tablescroll {
		display: block !important;
	}

	body, main, h1 {
		@include reset;
	}

	// ensure reports use full width of page
	h1 {
		width: 100%;
		float: left;

		header & {
			margin-left: 0;
		}
	}

	a:link, a:visited {
		color: #000 !important;
	}

	article {
		min-width: 100%;
	}

	header, #teststatus, #userstatus {
		position: static;
	}

	table, col, tr, th, td, fieldset, div.subfields {
		border-color: #999 !important;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	table {
		width: auto;
		min-width: auto !important;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	section {
		border: 0 none;
	}

	#comments {
		height: auto;
		max-height: none;
		border: 0 none !important;
	}

	blockquote.speech p {
		border: 1px solid #999;
	}

	blockquote.speech p:after {
		border-color: #999 #999 transparent transparent !important;
	}

	blockquote.speech.client p:after {
		border-color: #999 transparent transparent #999 !important;
	}

	ol#media {

		margin: 0;

		li {
			max-width: calc(33.3% - 12px);
			border-color: #999 !important;

			figure {
				overflow: hidden;
			}

		}

	}

	#dashboard section, #report section {
		float: left;
		width: 48%;
		margin: 1em 1%;
		border: 2px solid #999;
		page-break-inside: avoid;
		break-inside: avoid;

		&.wide {
			float: none;
			width: 100%;
			margin: 1em auto;
		}

		h2 {
			padding: 0 0 5px 0;
			border-bottom: 1px solid #999;
		}

	}

	.sectiongroup {
		clear: both;
	}

	.cards > li {
		display: inline-block;
    width: 31.5%;
    padding: 0;
		margin: 0 1% 1% 0;
		border: 2px solid #999;

		figure {
			max-height: none;
			overflow-y: hidden;
			border-top: 0 none !important;
			border-bottom: 1px solid #999;
		}

		.link {
			display: none;
		}
	}

	svg {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	svg.piechart {
		max-width: 180px;
	}

	text {
		fill: #000 !important;
		stroke-width: 0 !important;
	}

	path {
		stroke: #fff !important;
	}

	circle, ellipse {
		stroke: #fff !important;

		&.inner {
			fill: #fff !important;
		}
	}

}
