// widgets - primarily used on the dashboard
section {

	position: relative;

	h2 {
		font-size: 0.85em;
		padding: 2px 5px 3px 5px;
		margin: 5px;
	}

	a.icon-floppy {
		visibility: hidden;
		float: right;
		width: 1.2em;
		white-space: nowrap;
		overflow: hidden;
	}

	&:hover a.icon-floppy {
		visibility: visible;
	}

	span.icon-cancel {
		float: right;
		width: 1.2em;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
	}

	ul, ol, li {
		@include reset-list;
	}

	ul, ol, p:last-child {
		margin-bottom: 10px;
	}

	p, li {
		font-size: 1.25em;
		font-weight: 300;
		margin: 0 10px;
	}

	table {
		width: auto;
		min-width: calc(100% - 10px);
		margin: 0 auto;
	}

	a:link, a:visited, a:hover, a:focus, a:active {
		// prevents sub-theme color overrides
		color: inherit !important;
	}

	p.help {
		float: right;
		font-size: 0.75em;

		a:before {
			width: 1.5em;
			height: 1.5em;
			line-height: 1.5;
			margin: 0;
			border-radius: 50%;
		}
	}

	p.alert {
		float: none;
		font-size: 0.9em;
		text-align: center;

		a:before {
			margin-right: 0.2em;
		}
	}

	p.flash {
		transform-origin: 1.2em 50%;
		animation: flash 0.2s ease-in 1s alternate 6;
	}

	hr:last-child {
		height: 0;
	}

	.main {
		font-size: 3em;
		letter-spacing: -0.04em;
		line-height: 1;
		margin-top: 0.4em;
		margin-bottom: 0.2em;
	}

	h2 + .main {
		margin-top: 0;
	}

	@mixin status-color($name) {
		.status#{$name} {
			color: #{map-get($color-status, $name)};

			a:before {
				@extend %icon;
				margin-right: 0.2em;
				content: '\e863';
			}
		}
	}

	@include status-color("500");
	@include status-color("1000");
	@include status-color("1500");
	@include status-color("2000");
	@include status-color("3000");
	@include status-color("4000");

	strong {
		float: right;
		padding-left: 1em;
	}

	progress {
		position: absolute;
		display: block;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 5px;
		@include reset;
		border: 0 none;
	}

	ul.barchart {
		margin: 5px;
	}

	a {
		display: block;
	}

	p.secondary {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
	}

}

// HTML bar chart
ul.barchart {

	clear: both;
	list-style-type: none;
	padding: 1px;
	margin: 1em auto;

	li {
		font-size: 1em;
		padding: 0 3px;
		margin: 0 0 1px 0;
	}

	li:last-child {
		margin: 0;
	}

	a {
		padding: 0;
	}

	strong {
		float: right;
		padding-left: 1em;
	}

}

// fix IE issues
svg {
	overflow: hidden;
}

// pie charts
svg.piechart {

	display: block;
	max-width: 360px;
	margin: 0 auto;

	text {
		font-size: 2.6em;
		font-weight: 600;
		text-anchor: end;
		dominant-baseline: middle;
		pointer-events: none;
		stroke-width: 0.15em;
		fill: #222;

		@supports (paint-order: stroke) {
			stroke: rgba(255,255,255,0.9);
			stroke-linecap: round;
			stroke-linejoin: round;
			paint-order: stroke;
			animation: textfade 1.5s linear;
		}

	}

	text.rev {
		text-anchor: start;
	}

	path, circle, ellipse {
		stroke-width: 1;

		@supports (paint-order: stroke) {
			transform-origin: 50% 50%;
			animation: grow 1s ease-out;
		}
	}

}

// stacked bar chart
svg.barchart {

	text {
		font-size: 1em;
		text-anchor: end;
		dominant-baseline: middle;
	}

	text.total {
		font-size: 0.8em;
		font-weight: 600;
		text-anchor: start;

		@supports (paint-order: stroke) {
			animation: textfade 1.2s linear;
		}
	}

	text.value {
		font-size: 0.8em;
		font-weight: 600;
		fill: rgba(0,0,0,0.7);
		pointer-events: none;

		@supports (paint-order: stroke) {
			animation: textfade 1.8s linear;
		}
	}

	text.axism {
		font-size: 0.8em;
		text-anchor: start;
		dominant-baseline: hanging;
	}

	line {
		stroke-width: 1;
		stroke-linecap: butt;
	}

	line.axism {
		stroke-width: 0.5;
		stroke-dasharray: 1, 2;
	}

	rect {
		@supports (paint-order: stroke) {
			animation: growX 1s ease-out;
		}
	}

}

svg + svg.piechart, svg + svg.barchart {
	margin-top: 1em;
}

// alert widget
body#dashboard main {

	section.announcements {
		display: none;
		// flex: 2 1 35em; - double-width alert box

		p {
			margin: 0;
		}

		li, p {
			font-size: 1em;
			margin-bottom: 1em;
		}

		li ul, li li {
			list-style-type: disc;
			margin: 1em 0 1em 1.8em;
		}

		li li {
			margin: 0.3em 0;
		}

		a h3:after {
			content: '\e818';
			display: inline-block;
			font-family: "icons";
			font-weight: normal;
			padding-left: 0.25em;
			transform: translateX(0) rotate(-30deg);
			transition: transform 0.2s ease;
		}

		a:hover h3, a:focus h3 {

			&:after {
				transform: translateX(0.25em) rotate(0deg);
				animation: 1.5s ease-out 1s forwards infinite linkarrow;
			}

		}

	}

	p.announcements {
		display: block;
	}

	// p.bottom {
	// 	bottom: 0;
	// 	position: absolute;
	// }

}

body#dashboard.announcements-open main {

	section.announcements {
		display: block;
	}

	p.announcements {
		display: none;
	}

}

// maps
body#dashboard main section.map {

	flex: 3 1 59em;

	div {
		position: relative;
		margin: 0 4px;
		overflow: hidden;
	}

}

svg.map {
	width: 100%;
	min-height: 15em;
	text-anchor: middle;
	stroke-width: 1;

	path {
		transition: fill 0.3s linear;
	}

}

// map pin
ul.pin {
	position: absolute;
	display: none;
	left: 0;
	top: 0;
	font-size: 0.7em;
	@include reset-list;
	user-select: none;

	&::after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		left: calc(50% - 6px);
		bottom: -12px;
		border: 6px solid transparent;
		pointer-events: none;
	}

	li {
		text-align: left;
		white-space: nowrap;
		line-height: 1.2;
		@include reset;

		a, span {
			padding: 0 3px;

			span {
				float: right;
				padding: 0;
			}
		}

		img {
			width: 130px;
			margin: 0 auto;
		}

		&.title, &.subtitle {
			font-weight: 600;
			padding: 2px 0;
		}

		&.mi a:before {
			font-family: "icons";
			content: '\e833';
		  font-weight: normal;
			display: inline-block;
			padding-right: 2px;
		}

		&.ms a:before {
			font-family: "icons";
			content: '\e864';
		  font-weight: normal;
			display: inline-block;
			padding-right: 2px;
		}

	}
}

// loader
svg#load {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	circle {
		stroke: #ee750d;
		stroke-width: 4px;
		fill: transparent;
		transform-origin: 50%;
		animation: loadline 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, loadrotate 1.6s linear infinite;
	}

	&.inactive {
		display: none;
	}
}

.cards ul.link li {
	
	a span:after {
		content: '\e818';
		display: inline-block;
		font-family: "icons";
		font-weight: normal;
		padding-left: 0.25em;
		transform: translateX(0) rotate(-30deg);
		transition: transform 0.2s ease;
	}

	a:hover span, a:focus span {

		&:after {
			transform: translateX(0.25em) rotate(0deg);
			animation: 1.5s ease-out 1s forwards infinite linkarrow;
		}

	}
}

// flash animation for alerts
@keyframes flash {
	0% { transform: scale(1); }
	100% { transform: scale(0.6); }
}

// grow animation for charts
@keyframes grow {
	0% { transform: scale(0); }
	100% { transform: scale(1); }
}

// grow animation for bar charts
@keyframes growX {
	0% { transform: scaleX(0); }
	100% { transform: scaleX(1); }
}

// text fade animation for SVG text
@keyframes textfade {
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

// alert linkarrow
@keyframes linkarrow {
	0% { transform: translateX(0.25em); }
	15% { transform: translateX(0.5em); }
	30% { transform: translateX(0.25em); }
	100% { transform: translateX(0.25em); }
}

// loading icon rotation
@keyframes loadrotate {
  0% {
		transform: rotate(0);
	}
  100% {
		transform: rotate(450deg);
	}
}

// loading icon line effect
@keyframes loadline {
  0% {
		opacity: 0;
		stroke-dasharray: 2, 86;
		transform: rotate(0);
	}
  50% {
		opacity: 1;
    stroke-dasharray: 66, 22;
    stroke-dashoffset: 0;
	}
  100% {
    stroke-dasharray: 2, 86;
    stroke-dashoffset: -66;
    transform: rotate(90deg);
	}
}
