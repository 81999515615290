// issues
button.active, .button.active {

	span:before {
		content: '\e808';
		animation: spin 1s linear infinite;
	}

}

#testissues {
	visibility: hidden;

	&.active {
		visibility: visible;
	}

	label {
		white-space: inherit;
	}
}

#testissues p.showhide {
	clear: right;
	float: right;
	font-size: 0.85em;
	margin: 1em 0;
	cursor: pointer;
}

#issuelink {

	display: none;
	list-style-type: none;
	padding: 0;
	margin: 0 2em 1em 2em;

	li {
		text-indent: -1em;
	}

}

#assign {
	margin-bottom: 0;
}

ul#commentbuttons.buttons.alt.pad {
	margin-left: $label-width;
}

// issue comments
#comments {

	width: 10em;
	max-height: 70vh;
	list-style-type: none;
	padding: 0 0.5em;
	margin: 0;
	text-overflow: clip;
	overflow-x: hidden;
	overflow-y: auto;

	li {
		@include reset;

		blockquote.speech.admin {
			margin-right: 2em;
		}

		blockquote.speech.client {
			margin-left: 2em;
		}

		&.animate blockquote.speech {
			transform: scaleY(0);
			opacity: 0;
			transition: transform 0.3s cubic-bezier(0.6, 1, 0.2, 1.5);
		}

		&.animate.in blockquote.speech {
			opacity: 1;
			transform: scaleY(1);
		}
	}

}

blockquote.speech {

	width: 100%;
	font-size: 1rem;
	padding: 0;
	margin: 0 0 0.5em 0;
	text-overflow: clip;
	overflow: hidden;

	strong {
		display: block;
	}

	p, cite {
		width: auto;
		text-align: left;
		font-size: 1em;
		@include reset;
	}

	p {
		position: relative;
		display: block;
		font-size: 1em;
		line-height: 1.1;
		padding: 0.5em 0.75em;
		margin: 0 0 10px 0;
		white-space: pre-wrap;
		border-radius: 5px;
	}

	p:after {
		display: block;
		position: absolute;
		left: 0.5em;
		bottom: -9px;
		content: ' ';
		width: 0;
		height: 0;
		border-width: 5px;
		border-style: solid;
	}

	cite {
		display: block;
		font-size: 0.9em;
		font-style: normal;
		line-height: 1.2;
		padding: 0 32px;
	}

	img {
		float: left;
		max-width: 20px;
		margin-left: -24px;
		border-radius: 20%;
	}

	a {
		outline: 0 none;
		text-overflow: clip;

		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	&.admin {
		margin-right: 2em;
	}

	&.client {

		p:after {
			left: auto;
			right: 0.5em;
		}

		cite {
			text-align: right;
		}

		img {
			float: right;
			margin-left: 0;
			margin-right: -24px;
		}
	}

}


// spin animation for message send button
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
