// Webfont icons

@font-face {
  font-family: 'icons';
  src:
    resolve('icons.eot');
  src:
    resolve('icons.eot#iefix') format('embedded-opentype'),
    resolve('icons.woff') format('woff'),
    resolve('icons.ttf') format('truetype'),
    resolve('icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  user-select: none;
  speak: none;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  @extend %icon;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

[class^="icon-file-"]:before, [class*=" icon-file-"]:before {
  width: 1.1em;
  padding: 0.1em 0;
}

.icon-down-mini:before { content: '\e800'; }
.icon-left-mini:before { content: '\e801'; }
.icon-right-mini:before { content: '\e802'; }
.icon-up-mini:before { content: '\e803'; }
.icon-down-big:before { content: '\e804'; }
.icon-left-big:before { content: '\e805'; }
.icon-right-big:before { content: '\e806'; }
.icon-up-big:before { content: '\e807'; }
.icon-arrows-ccw:before { content: '\e808'; }
.icon-star:before { content: '\e809'; }
.icon-star-empty:before { content: '\e80a'; }
.icon-user:before { content: '\e80b'; }
.icon-users:before { content: '\e80c'; }
.icon-user-add:before { content: '\e80d'; }
.icon-clock:before { content: '\e80e'; }
.icon-cog:before { content: '\e80f'; }
.icon-search:before { content: '\e810'; }
.icon-attach:before { content: '\e811'; }
.icon-comment:before { content: '\e812'; }
.icon-chat:before { content: '\e813'; }
.icon-plus-squared:before { content: '\e814'; }
.icon-phone:before { content: '\e815'; }
.icon-reply:before { content: '\e816'; }
.icon-reply-all:before { content: '\e817'; }
.icon-forward:before, .icon-imgpreland:before { content: '\e818'; }
.icon-quote:before { content: '\e819'; }
.icon-pencil:before { content: '\e81a'; }
.icon-check:before { content: '\e81c'; }
.icon-cancel:before { content: '\e81d'; }
.icon-gauge:before { content: '\e81e'; }
.icon-cancel-squared:before { content: '\e81f'; }
.icon-mail:before { content: '\e820'; }
.icon-vcard:before { content: '\e821'; }
.icon-block:before { content: '\e823'; }
.icon-resize-full-1:before { content: '\e824'; }
.icon-arrow-combo:before { content: '\e825'; }
.icon-down-dir:before { content: '\e826'; }
.icon-left-dir:before { content: '\e827'; }
.icon-right-dir:before { content: '\e828'; }
.icon-up-dir:before { content: '\e829'; }
.icon-play:before { content: '\e82a'; }
.icon-stop:before { content: '\e82b'; }
.icon-pause:before { content: '\e82c'; }
.icon-to-end:before { content: '\e82d'; }
.icon-to-start:before { content: '\e82e'; }
.icon-fast-forward:before { content: '\e82f'; }
.icon-fast-backward:before { content: '\e830'; }
.icon-globe:before { content: '\e831'; }
.icon-eye:before { content: '\e832'; }
.icon-chart-pie:before { content: '\e833'; }
.icon-chart-line:before { content: '\e834'; }
.icon-chart-bar:before { content: '\e835'; }
.icon-chart-area:before { content: '\e836'; }
.icon-floppy:before { content: '\e837'; }
.icon-clipboard:before { content: '\e838'; }
.icon-share:before { content: '\e83d'; }
.icon-thumbs-up:before { content: '\e83e'; }
.icon-thumbs-down:before { content: '\e83f'; }
.icon-info:before { content: '\e840'; }
.icon-help:before { content: '\e841'; }
.icon-link:before, .icon-imgbanner:before { content: '\e842'; }
.icon-lock:before { content: '\e843'; }
.icon-lock-open:before { content: '\e844'; }
.icon-plus:before { content: '\e845'; }
.icon-minus:before { content: '\e846'; }
.icon-trash:before { content: '\e847'; }
.icon-attention:before { content: '\e849'; }
.icon-rss:before { content: '\e84a'; }
.icon-record:before { content: '\e84b'; }
.icon-target:before { content: '\e84c'; }
.icon-list:before { content: '\e84d'; }
.icon-signal:before { content: '\e84e'; }
.icon-mobile:before { content: '\e84f'; }
.icon-monitor:before { content: '\e850'; }
.icon-tablet:before { content: '\e822'; }
.icon-credit-card:before, .icon-imglanding:before { content: '\e851'; }
.icon-menu:before { content: '\e852'; }
.icon-minus-squared:before { content: '\e854'; }
.icon-print:before { content: '\e855'; }
.icon-tag:before { content: '\e856'; }
.icon-bookmark:before { content: '\e857'; }
.icon-list-add:before { content: '\e858'; }
.icon-network:before { content: '\e859'; }
.icon-calendar:before { content: '\e85a'; }
.icon-home:before { content: '\e85b'; }
.icon-camera:before { content: '\e85c'; }
.icon-ccw:before { content: '\e85e'; }
.icon-cw:before { content: '\e85f'; }
.icon-video:before { content: '\e860'; }
.icon-picture:before { content: '\e861'; }
.icon-flag:before { content: '\e863'; }
.icon-layout:before { content: '\e864'; }
.icon-resize-full:before { content: '\e865'; }
.icon-resize-small:before { content: '\e866'; }
.icon-resize-small-1:before { content: '\e867'; }
.icon-resize-vertical:before { content: '\e868'; }
.icon-resize-horizontal:before { content: '\e869'; }
.icon-move:before { content: '\e86a'; }
.icon-popup:before { content: '\e86b'; }
.icon-txt:before { content: '\e86e'; }

// applications
.icon-word:before { content: '\e839'; }
.icon-excel:before { content: '\e83a'; }

// file types
.icon-pdf:before { content: '\e81b'; }
.icon-file-doc:before, .icon-file-rtf:before { content: '\e839'; color: #ddd; background-color: #295391; }
.icon-file-xls:before, .icon-file-csv:before { content: '\e83a'; color: #ddd; background-color: #1f6b41; }
.icon-file-pdf:before { content: '\e81b'; color: #ddd; background-color: #7b0000; }
.icon-file-ppt:before { content: '\e848'; color: #ddd; background-color: #c44423; }
.icon-file-zip:before { content: '\e862'; color: #fb0; }
.icon-file-htm:before { content: '\e86d'; color: #1f6b41; }
.icon-file-txt:before, .icon-file-odp:before, .icon-file-ods:before, .icon-file-odt:before { content: '\e86e'; }
.icon-file-image:before { content: '\e86c'; }
.icon-file-vid:before { content: '\e86f'; }
.icon-file-video:before { content: '\e86f'; }
.icon-file-audio:before { content: '\e870'; }
