// test page
#teststatus {

	@extend %top-bar;

	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	div {
		margin: 0 0.25em;

		&.ref {
			flex: 1 2 auto;
		}

	}

}

#testservice {

	div.novisible {
		visibility: hidden;
	}
	
}

#dupissuemessage {
	margin: 0 auto 1em;

	div {
		padding: .5em;
		font-size: medium;
		font-weight: 400;
		margin: 0 auto;
	}

}

li.new {

	&:before {
		display: inline-block;
		font-family: "icons";
		content: '\e849';
		width: 0.75em;
		cursor: help;
	}

}

#ref {
	width: 100%;
	font-size: 1.25em;
	font-weight: 600;
}

input#ref {
	height: 1.85em;
}

#status {
	font-weight: 600;
}

output#status {
	padding: 0;

	&:before {
		margin-right: 0.25em;
	}
}

input#smscode, input#smskeyword {
	max-width: 10em;
}

.subfields {

	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0.8em 0;
	margin: 0 0 1em 0;

	fieldset {
		flex: 1 1 auto;
		padding: 0.2em 0;
		margin: 0;
		overflow: hidden;

		@include respond('medium') {
			flex-basis: 50%;
		}
	}

}

.assign {
	margin-bottom: 0;
	padding-bottom: 0;
}

.hide {
	display: none;
}

#targetdiv {
	display: block;
}

#testissues {

	padding: 1em 2em;

	div {
		justify-content: flex-start;
		margin-left: 0;
		margin-right: 0;
		break-inside: avoid;

		&#contact {
			display: block;
			float: right;

			.button {
				width: 100%;
			}

			p {
				text-align: left;
				padding: 0;
			}
		}
	}

	p {
		display: block;
		font-size: 1em;
		margin: 0 0 0.75em 0;
		break-inside: avoid;
		user-select: text;
	}

	section.cols {

		column-gap: 0;
		margin-left: 8em;
		margin-bottom: 0.5em;

		input[type="checkbox"] {
			margin-left: 0;
		}

		label {
			white-space: nowrap;
		}

		@include respond('medium') {
			column-count: 2;

			p {
				display: inline-block;
			}
		}

	}

}

#readonly #testissues section {

	column-gap: 2em;
	margin: 0 0 1em 0;

	@include respond('large') {
		column-count: 1;
	}

	p {
		padding-left: 1.4em;
		text-indent: -0.8em;

		&::before {
			font-family: 'icons';
			content: '\e823';
		  display: inline-block;
		  width: 0.8em;
		  text-align: center;
		}
	}

}
