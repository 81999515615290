// file drag and drop styling
#filehover {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	font-family: "icons";
	font-size: 100vmin;
	line-height: 1;
	text-align: center;
	margin: auto;
}

body.filehover #filehover {
	display: block;
}
