// report table
table.report {

	table-layout: fixed;

	th, td {
		width: 3em;
		text-align: right;
		direction: rtl;
		padding: 1px 2px;
		text-overflow: clip;

		a {
			display: block;
		}

		&.title {
			width: auto;
			text-align: left;
			direction: ltr;
			text-overflow: ellipsis;
		}

		&.wider {
			width: 5.5rem;
		}

		&.nodata {
			text-align: center;
		}
	}

	th {
		vertical-align: bottom;

		span {
			padding-top: 4px;
			writing-mode: vertical-rl;
		}
	}

	// reports with active links
	&[data-linkurl] td {
		cursor: pointer;

		&.z {
			cursor: auto;
		}
	}

}

table.ops {

	th, td {
		padding: 0 4px;
	}

	td span {
		float: left;
	}

	th.date {
		width: 6em;
	}

	th.tests {
		width: auto;
	}

	th.quality {
		width: 4.25em;
	}

	.q {
		font-size: 1.85em;
	}

}

a.report-help {
	font-size: 0.5em;
	vertical-align: super;
}

ul.downloads {
	float: right;
	list-style-type: none;
	padding: 0;
	margin: 0.5em 0 0.5em 1em;
}

// table data message
p.reportmessage {
	text-align: center;
	margin: 1.5em auto;
}

// chart wrapper
body#report div.chartwrapper {
	max-height: 800px;
	overflow-y: auto;
	display: block;
}

// report chart
body#report form > svg {
	margin-top: 1em;
	margin-bottom: 1em;
}
