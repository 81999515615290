// list pages
#filter {

	@extend %top-bar;

	div.filters {
		flex-wrap: nowrap;
		justify-content: left;
		padding: 0;

		&.wrap {
			flex-wrap: wrap;
		}

		&:focus-within {
			flex-wrap: wrap;
		}
	}

}

div.filters {

	clear: both;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0.2em 0;
	margin: 0;

	div, input, select, button, #published {
		flex: 1 1 auto;
		order: 1;
		width: auto;
		min-width: 3em;
		margin: 0.2em 0.25em;
	}

	button {
		flex: 0 0 auto;
		width: 3em;
		min-width: auto;
		margin-left: auto;

		&#submit-report {
			order: 0;
			margin-left: unset;
		}
	}

	input[type="date"], #month {
		max-width: 9.25em;
	}

	input[type="year"], input[type="number"], #ps {
		max-width: 4.5em;
	}

	input.fitcontent, select.fitcontent {
		max-width: fit-content;
	}

	#period {
		max-width: 5em;
	}

	#country {
		max-width: 9em;
	}

	#gm, #complete, #active {
		flex: 1 0 auto;
		width: 5.5em;
	}

	#gm {
		font-weight: 600;
	}

	// checkbox override
	div {

		flex: 0 0 auto;

		input[type="checkbox"], input[type="radio"] {
			width: 2em;
			min-width: auto;
			padding: 0;

			&:focus ~ label {
				font-weight: 300;
			}
		}

	}

}

div.sepfilters {
	padding: 0;
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 3em;
	}
}

div.advanced {
	position: relative;
	top: $top-bar-height - 5em;
	height: 0;
	transform: scaleY(0);
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	@include reset;
	transform-origin: 50% 0;
	transition: transform 0.2s ease-in-out;

	& > fieldset {
		flex: 1 1 auto;
		padding: 0.2em 0;
		margin: 0;
	}

	fieldset {

		div {
			margin: 2px 0.8em;
		}

		label {
			width: 8.2em;
		}

	}
}

.advanced-open {

	div.advanced {
		height: auto;
		transform: scaleY(1);
	}

	button.toggler.icon-menu::before {
		content: '\e807';
	}

}

@mixin status-flag($name) {
	body th.status#{$name}, body td.status#{$name}, body output.status#{$name} {
		color: #{map-get($color-status, $name)};

		&:before {
			@extend %icon;
			content: '\e863';
		}
	}
}

@include status-flag("0");
@include status-flag("500");
@include status-flag("1000");
@include status-flag("1500");
@include status-flag("2000");
@include status-flag("3000");
@include status-flag("4000");

@mixin show-icon($class, $content) {
	td.#{$class}:before {
		@extend %icon;
		content: "#{$content}";
	}
}

@include show-icon("published", "\e81c");
@include show-icon("active", "\e81c");

@include show-icon("marketing", "\e84c");
@include show-icon("payment", "\e851");
@include show-icon("careline", "\e815");

@include show-icon("cancomment", "\e813");
@include show-icon("canfix", "\e83e");
@include show-icon("canverify", "\e832");
@include show-icon("canedit", "\e81a");
