// header
header {
  @extend %top-bar;
}

// primary content holder
main {
  position: relative;
  padding: 0 2em 5em 2em;
  margin: 0 0 0 map-get($menu-width, 'small');
  z-index: 0;

  @include respond('medium') {
    margin-left: map-get($menu-width, 'medium');
  }

  @include respond('large') {
    margin-left: map-get($menu-width, 'large');
  }
}

body.full {
  padding: 0;
  overflow: hidden;

  main {
    position: absolute;
    top: 3em;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
}

article {
  max-width: 40em;
}

iframe.full {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include reset;
  border: 0 none;
  overflow: auto;
}

// footer
footer {
	position: fixed;
  left: 0;
	bottom: 0;
	width: map-get($menu-width, 'small');
  padding-bottom: 10px;

  p {
    display: none;
    font-size: 0.8em;
    text-align: center;
    @include reset;
  }

  @include respond('medium') {
    width: map-get($menu-width, 'medium');
  }

  @include respond('large') {
    width: map-get($menu-width, 'large');

    p {
      display: block;
    }
  }
}
