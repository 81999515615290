// navigation menu
nav.menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: map-get($menu-width, 'small');

  ul, li {
    @include reset-list;
  }

  a {
    display: block;
    text-transform: uppercase;
    line-height: $top-bar-height;
    white-space: nowrap;
    overflow: hidden;
    outline: 0 none;

    &:before {
      width: map-get($menu-width, 'small');
      margin: 0;
    }
  }

  .home a {
    height: $top-bar-height;
    padding: 0 2px;
    margin-bottom: 1em;
    overflow: hidden;
    outline: 0 none;
  }

  @include respond('medium') {
    width: map-get($menu-width, 'medium');
  }

  @include respond('large') {
    width: map-get($menu-width, 'large');
  }
}
