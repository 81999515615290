// SVG logo styles
svg.fraudscan {
	display: none;
}

// Chrome can target referenced <svg>, Firefox can only target <use>
svg.fraudscan path.fstext, .logobig {
	fill: #222;

	body.dark & {
		fill: #ddd;
	}
}

.home a {

	svg {
		display: none;
		max-width: 100%;
		max-height: 100%;
	}

	.logosml {
		display: block;
	}

	@include respond('large') {

		.logosml {
			display: none;
		}

		.logobig {
			display: block;
		}

	}
}
