// form styling
$label-width: 8em;
$label-pad: 1em;
$input-padx: 0.6em;
$input-pady: $input-padx * 2 / 3;

form {
  @include reset;

  div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 2px 2em;

    &.tall {
      align-items: flex-start;

      p, label {
        padding-top: $input-pady;
      }
    }

    &.issuecompany {
      align-items: flex-start;
    }
  }

  div.ggchart, div.ggchart div {
    margin: 0;
  }

  a.addlink {
    margin-left: 8em;
  }

  nav {
    @extend %button-bar;
  }

  nav ul, ul.buttons {

    float: right;
    @include reset-list;

    li {
      display: inline-block;
      padding: 0;
      margin: 0 0 4px 4px;
    }

    &.alt {
      float: left;

      &.pad {
        margin-left: $label-width + 2em;
      }

      li {
        margin: 0 4px 4px 0;
      }
    }

  }

  nav.login {
    padding: 0;
  }

  nav p {
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0;

    a {
      display: block;
      padding: 8px 0;
    }
  }

}

fieldset {
  padding: 1em 0;
  margin: 0 0 1em 0;
  border: 0 none;

  fieldset {
    @include reset;
  }
}

legend {
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 1em 0;
}

label {
  order: 1;
  width: $label-width;
  text-align: right;
  padding-right: $label-pad;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;

  &.longlabel {
    white-space: inherit;
  }
}

input, output, textarea, select, pre {
	appearance: none;
  order: 2;
  flex: 2 1 auto;
  height: 2.25em;
  padding: $input-pady $input-padx;
  margin: 0;
  border: 0 none;
	border-radius: 0;
  outline: 0 none;
  box-shadow: none;
}

select {
  padding-left: $input-pady;
  outline: 0 none;
  cursor: pointer;
}

textarea {
  height: auto;

  &[disabled], &[readonly] {
    font-size: 0.85em;
    padding-top: 0;
    white-space: pre-wrap;
  }

  &#url_notes {
    min-height: 27px !important;
  }
}

pre {
	font-size: 0.85em;
	width: 10em;
	height: auto;
	padding-top: 0;
	margin-top: 0;
	word-wrap: break-word;
	white-space: pre-wrap;
	overflow: auto;

	span.domain {
		font-weight: 600;
	}
}

input[type="file"] {
  height: auto;
  padding: 0.4em;
  cursor: pointer;
}

input[type="checkbox"], input[type="radio"] {
  order: 1;
  flex: 0 0 auto;
  width: 2em;
  height: auto;

  div & {
    margin-left: $label-width;
  }

  div span.chkcol & {
    margin-left: 0;

    & ~ label {
      width: 0;
    }
  }

  & ~ label {
    position: relative;
    width: auto;
    left: -2em;
    text-align: left;
    padding-left: 0;
    padding-right: 0;

    &::before, &::after {
      display: inline-block;
      font-family: "icons";
      content: '\e81d';
      width: 2.6em;
      vertical-align: top;
      text-indent: 1.7em;
      margin-right: $input-padx;
    }

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 1.1em;
      height: 80%;
      left: 0.15em;
      top: 10%;
      transform: translateX(0);
      transition: transform 70ms ease-in;
    }

  }

  &:checked ~ label {

    &::before {
      content: '\e81c';
      text-indent: 0.3em;
    }

    &::after {
			transform: translateX(1.25em);
    }
  }
}

form div {

  p {
    width: $label-width;
    text-align: right;
    padding: 0 $label-pad 0 0;
    margin: 0;
    user-select: none;

    & ~ input[type="checkbox"], & ~ input[type="radio"] {
      margin-left: 0;
    }
  }

  .chkcol {
    width: 4.5em;
    text-align: left;
  }

  span.chkcol {
    display: flex;
  }

  ol, ul {
    order: 2;
    flex: 2 1 auto;
    @include reset;
  }

  li {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @include reset;
    margin: 0.1em 0;

    &.template {
      display: none;
    }
  }

  .button {
    order: 3;
    width: 2.5em;
  }

}

button, .button {
  display: inline-block;
  width: 11.5em;
  height: 2.25em;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  line-height: 2.2;
  padding: 0;
  margin: 0;
  border: 0 none;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  user-select: none;
  cursor: pointer;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  span[class^="icon-"], span[class*=" icon-"] {
    padding-right: 0.6em;
  }

  &[disabled] {
    cursor: default;
  }

}

#readonly {

  form div.tall {

    & > p {
      padding-top: 0;
    }

    li {
      margin-top: 0;
    }

  }

  output {
    height: auto;
    padding: 0 $input-padx 0 0;
  }

  label {
    cursor: default;
  }

  a.url {
    display: block;
    order: 2;
    max-width: 16em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ol#media li figure {
    min-height: 3.5em;
  }

}

// copyable field
label.copyable.active {

  &:before {
    display: inline-block;
    visibility: hidden;
    font-family: 'icons';
    content: '\e838';
    padding-right: 0.25em;
    cursor: copy;
  }

  &:hover:before {
    visibility: visible;
  }

}

#readonly label.copyable {
  cursor: copy;
}

div.copier {
  display: block;

  label.copyable {
    width: auto;
    text-align: left;
    margin-left: $label-width;
  }
}
