// test/user updated list
.updated {

	p {
		float: right;
		font-size: 0.85em;
		width: 2em;
		height: 2em;
		text-align: center;
		line-height: 1.95;
		@include reset;
		border-radius: 50%;
		cursor: pointer;
		transition: background-color 0.35s linear;
	}

	ol {
		position: absolute;
		font-size: 0.85em;
		right: 0;
		transform: translateX(100%);
		top: $top-bar-height + 1.5em;
		list-style-type: none;
		padding: 1em;
		margin: 0;
		transition: transform 0.35s ease;

		&:before {
			position: absolute;
			display: block;
			content: "";
			width: 0;
			top: -20px;
			right: 0.9em;
			border: 10px solid transparent;
		}
	}

	img {
		width: 20px;
		margin: 0 5px 0 0;
		border-radius: 8%;
	}

	li[title] {
		cursor: help;
	}

	li.sep {
		padding-top: 0.25em;
		margin-top: 0.25em;
	}

	&:hover ol {
		transform: translateX(-2em);
	}
}
