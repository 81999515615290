// table styling
p.nodata {
	display: none;
	margin: 1em 0;
}

.nodata {

	p.nodata {
		display: block;
	}

	table, nav.tablenav {
		display: none;
	}

}

.tablescroll {
	overflow-x: auto;
	margin: 0;
}

table {
	width: 100%;
	font-size: 1em;
	font-variant-numeric: tabular-nums;
	table-layout: fixed;
	border-spacing: 0;
	border-collapse: collapse;
	empty-cells: show;
	user-select: none;
}

form.active {

	button.icon-search {
		display: none;
	}

	table {
		cursor: pointer;
	}

	th {
		cursor: ns-resize;
		user-select: none;
	}

	a {
		// prevents sub-theme color overrides
		color: inherit !important;
	}

}

th, td {
	font-weight: 300;
	text-align: left;
	vertical-align: middle;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0.3em;
	margin: 0;
	overflow: hidden;

	&.num, &.date {
		width: 3.5em;
		text-align: right;
		direction: rtl;
		text-overflow: clip;
	}

	&.date {
		width: 6.2em;
	}

	&.ref {
		width: 7.5em;
	}

	&.adflow {
		@include respond('medium') { width: 9em; }
	}

	&:before {
		width: auto;
		margin: 0;
	}

	&[class^="icon"] {
		width: 2.4em;
		text-overflow: clip;
		margin: 0;
	}

	sup {
		font-size: 0.6em;
		opacity: 0.6;
	}
}

th {
	font-weight: 600;
	text-overflow: unset;
}

td[rowspan] {
	vertical-align: top;
}

tbody tr.rowtemplate, div.testcardtemplate {
	display: none;
}

// div.testcardtemplate, 
div.testcard {

	position: absolute;
	z-index: 9999;

	// top: 25%;
	right: 20px;
	display: block;
	margin: 0;
	font-size: 0.7rem;

	time {
		float: right;
	}

	p {
		text-align: left;
		width: unset;
		padding: 0.8rem;
	}

	section.testcardbreach p {
		font-size: inherit;
		margin-bottom: 0;
		padding: 0;

		&:before {
			content: "\e823";
			display: inline-block;
			font-family: icons;
			text-align: center;
			width: 0.8em;
			color: #c00;
			padding-right: 1rem;
		}
	}

	div.subfields {
		padding: 0.8rem;
		margin: 0;

		output[data-type="status"] {
			padding: 0;
		}

		fieldset {
			padding: 0.2rem;
		}
	}

	table.issue {

		th {
			cursor: default;
			vertical-align: text-top;
		}
	}

	fieldset#testmedia {
		margin: 0;

		ol#media li {
			max-width: 80px;
		}
	}

}

table.info, table.help {

	table-layout: auto;
	margin-bottom: 1.5em;

	th, td {
		white-space: normal;
		vertical-align: top;
	}

}

table.info {

	th, td {
		text-align: right;
		direction: rtl;
		text-overflow: clip;
	}

	th.left-align, td.left-align {
		text-align: left;
		direction: ltr;
	}
	
}

table.help {

	td:first-child {
		font-weight: 600;
		text-align: right;
		white-space: nowrap;
		padding-left: 2em;
		padding-right: 0.5em;
	}

}

table.issue {
	table-layout: auto;
	width: auto;
	margin: 0 0 1em 0;

	&#issueinfo {
		display: inline-block !important;
	}

	th, td {
		padding: 0 1em 0 0;
	}
}

nav.tablenav {

	.sep {
		padding-left: 0.75em;
	}

	.pages {
		width: 3em;
	}

	button {
		width: 3em;
		margin-left: 0;
	}

}

// status table col mixin
@mixin status-col($name) {
	col.status#{$name} {
		$col: map-get($color-status, $name);
		background-color: rgba($col, 0.3);
	}
}

// status table col mixin
@mixin istate-col($name) {
	col.issue-state#{$name} {
		$col: map-get($color-istate, $name);
		background-color: rgba($col, 0.3);
	}
}

@include status-col("500");
@include status-col("1000");
@include status-col("1500");
@include status-col("2000");
@include status-col("3000");
@include status-col("4000");

@include istate-col("1");
@include istate-col("01");
@include istate-col("00");